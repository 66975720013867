import { create } from "zustand";

// Define constants for dialog types
export const DIALOG_TYPES = {
  HOME: "HOME",
  CHART: "CHART",
  FOOTPRINT: "FOOTPRINT",
  QUERY: "QUERY",
  DISCOVER: "DISCOVER",
  TEXT: "TEXT",
  TABLE: "TABLE",
};

export const useStoreDialog = create((set, get) => ({
  // State
  active: 0,
  outgoing: null,
  currentType: null,
  isEditing: false,
  editData: null,

  // Actions
  initialize: (type, isEditing, initialData) => {
    const currentState = get();
    console.log("Initializing dialog:", {
      type,
      isEditing,
      initialData,
      currentState,
    });

    set({
      isEditing,
      editData: initialData,
      currentType: type,
    });
  },

  goHome: () => {
    console.log("Going home from:", get());
    set({
      active: 0,
      outgoing: get().active,
      currentType: null,
      isEditing: false,
      editData: null,
    });
  },

  navTo: (current, target, type = null) => {
    console.log("Navigating:", { from: current, to: target, type });
    // Validate the navigation
    if (target < 0) {
      console.warn("Invalid navigation target:", target);
      return;
    }

    set({
      active: target,
      outgoing: current,
      currentType: type || get().currentType, // Preserve type if not provided
    });
  },

  startEdit: (widgetType, data) => {
    console.log("Starting edit:", { widgetType, data });
    set({
      isEditing: true,
      editData: data,
      currentType: widgetType,
    });
  },

  endEdit: () => {
    console.log("Ending edit");
    set({
      isEditing: false,
      editData: null,
    });
  },

  reset: () => {
    console.log("Resetting dialog state");
    set({
      active: 0,
      outgoing: null,
      currentType: null,
      isEditing: false,
      editData: null,
    });
  },
}));

// Helper hooks for common dialog operations
export const useDialogNavigation = () => {
  const { active, navTo, currentType } = useStoreDialog();

  return {
    navigateToType: (type) => {
      const currentActive = active;
      const targetIndex = getIndexForType(type);
      if (targetIndex !== undefined) {
        navTo(currentActive, targetIndex, type);
      }
    },
  };
};

// Helper function to get index for a dialog type
const getIndexForType = (type) => {
  const typeToIndex = {
    [DIALOG_TYPES.HOME]: 0,
    [DIALOG_TYPES.CHART]: 1,
    [DIALOG_TYPES.FOOTPRINT]: 2,
    [DIALOG_TYPES.QUERY]: 3,
    [DIALOG_TYPES.DISCOVER]: 4,
    [DIALOG_TYPES.TEXT]: 5,
    [DIALOG_TYPES.TABLE]: 6,
  };

  return typeToIndex[type];
};
