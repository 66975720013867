// WEVR.AI V2
// James Launder & Matt Webb
// 22/07/23
// Charting and News Completed enough to launch

import React from "react";
import ReactDOM from "react-dom";
import { createRoot } from "react-dom/client";
import { App } from "./App";
import reportWebVitals from "./reportWebVitals";
import { ThemeProvider } from "./Components/Darkmode/ThemeContext";
import "./index.css";
import "react-tooltip/dist/react-tooltip.css";
import "../node_modules/react-grid-layout/css/styles.css";
import "../node_modules/react-resizable/css/styles.css";
import { GoogleOAuthProvider } from "@react-oauth/google";
import dotenv from "dotenv";
import ReactGA from "react-ga4";
dotenv.config();

// Google analytics key
ReactGA.initialize("G-E7XCZRLHGN");
const container = document.getElementById("app");
const root = createRoot(container);
root.render(
  <React.StrictMode>
    <GoogleOAuthProvider clientId={process.env.GOOGLE_CLIENT_ID}>
      <ThemeProvider>
        <App />
      </ThemeProvider>
    </GoogleOAuthProvider>
  </React.StrictMode>
);
// ReactDOM.render(
//   <React.StrictMode>
//     <GoogleOAuthProvider clientId={process.env.GOOGLE_CLIENT_ID}>
//       <ThemeProvider>
//         <App />
//       </ThemeProvider>
//     </GoogleOAuthProvider>
//   </React.StrictMode>,
//   document.getElementById("app")
// );
const SendAnalytics = () => {
  ReactGA.send({
    hitType: "pageview",
    page: window.location.pathname,
  });
};
reportWebVitals(SendAnalytics);
