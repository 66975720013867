import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
} from "../../Ui/Dialog";
import { Button } from "../../Ui/button";
import { Input } from "../../Ui/input";
import { Loader2 } from "lucide-react";
import { useDashboardMutation } from "../API/useDashboardQueries";
import { useToast } from "../../Ui/use-toast";

export default function CreateDashboardDialog({ open, setOpen }) {
  const [dashboardName, setDashboardName] = useState("");
  const navigate = useNavigate();
  const { toast } = useToast();

  const { mutate: createDashboard, isLoading } = useDashboardMutation({
    onSuccess: (response) => {
      toast({
        variant: "successV2",
        title: "Dashboard created successfully",
      });
      setOpen(false);
      // Navigate to the new dashboard using the uniqueCode
      navigate(`/dashboard/${response.data.uniqueCode}`);
    },
    onError: (error) => {
      toast({
        variant: "destructive",
        title: "Failed to create dashboard",
        description: error.message || "Please try again",
      });
    },
  });

  const handleCreate = () => {
    if (!dashboardName.trim()) {
      toast({
        variant: "destructive",
        title: "Dashboard name required",
        description: "Please enter a name for your dashboard",
      });
      return;
    }

    createDashboard({
      data: {
        userID: "64044ac74381a8db4fb414d6", // TODO: Get from auth context
        dashboardName: dashboardName.trim(),
      },
    });
  };

  return (
    <Dialog open={open} onOpenChange={setOpen}>
      <DialogContent>
        <DialogHeader>
          <DialogTitle>Create New Dashboard</DialogTitle>
          <DialogDescription>
            Give your dashboard a name to get started.
          </DialogDescription>
        </DialogHeader>

        <div className="py-4">
          <Input
            placeholder="Dashboard name"
            value={dashboardName}
            onChange={(e) => setDashboardName(e.target.value)}
            disabled={isLoading}
          />
        </div>

        <DialogFooter>
          <Button
            variant="outline"
            onClick={() => setOpen(false)}
            disabled={isLoading}
          >
            Cancel
          </Button>
          <Button onClick={handleCreate} disabled={isLoading}>
            {isLoading ? (
              <>
                <Loader2 className="h-4 w-4 animate-spin mr-2" />
                Creating...
              </>
            ) : (
              "Create Dashboard"
            )}
          </Button>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
}
