import { useEditor, EditorContent } from "@tiptap/react";
import StarterKit from "@tiptap/starter-kit";
import TextAlign from "@tiptap/extension-text-align";
import EditorToolbar from "./EditorToolbar";
import { useEffect } from "react";

const TiptapEditor = ({
  content = "",
  onUpdate,
  editable = true,
  children,
}) => {
  const editor = useEditor({
    extensions: [
      StarterKit.configure({
        history: editable,
      }),
      TextAlign.configure({
        types: ["heading", "paragraph"],
        defaultAlignment: "left",
      }),
    ],
    content,
    editable,
    autofocus: editable ? "end" : false,
    onUpdate: ({ editor }) => {
      const htmlContent = editor.getHTML();
      const textContent = editor.getText();

      if (textContent.length <= 2000) {
        onUpdate?.(htmlContent);
      } else {
        editor.commands.undo();
      }
    },
  });

  useEffect(() => {
    if (editor && editable) {
      editor.commands.focus("end");
    }
  }, [editor, editable]);

  if (!editor) {
    return null;
  }

  return (
    <div className="flex flex-col h-full">
      {editable && <EditorToolbar editor={editor} />}
      <div
        className="flex-1 min-h-0 overflow-y-auto relative"
        onClick={() => {
          if (editable && editor) {
            editor.commands.focus("end");
          }
        }}
      >
        <EditorContent
          editor={editor}
          className="h-full prose prose-sm max-w-none px-4 py-2"
        />
        {editable && !editor.getText() && (
          <div className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 text-muted-foreground pointer-events-none">
            Click anywhere to start typing...
          </div>
        )}
      </div>
      {children}
    </div>
  );
};

export default TiptapEditor;
