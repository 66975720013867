import {
  createBrowserRouter,
  RouterProvider,
  Route,
  createRoutesFromElements,
  useRouteError,
  useNavigate,
} from "react-router-dom";
import Root from "./Root";
import Homepage from "./Components/Homepage/Homepage";
import Charting from "./Components/Charting/Charting";
import Payment from "./Components/Payment/Payment";
import SignUp from "./Components/User/SignUp";
import SignIn from "./Components/User/SignIn";
import UserSettings from "./Components/User/UserSettings";
import Faq from "./Components/Faq/Faq";
import News from "./Components/News/News";
import { default as Dashboard } from "./Components/Dashboard/Index";
import Legal from "./Components/Legal/Legal";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import ResetPass from "./Components/User/ResetPass";
import ForgotPass from "./Components/User/ForgotPass";
import Contact from "./Components/ContactUs/Contact";
import Portfolio from "./Components/Portfolio/Portfolio";
import DisplayPortfolio from "./Components/Portfolio/PortfolioPage/DisplayPortfolio";
import DisplayTransactions from "./Components/Portfolio/DisplayTransactions";
import DisplayNetworkGraph from "./Components/Portfolio/NetworkPage/DisplayNetworkGraph";
import DisplayAnalytics from "./Components/Portfolio/DisplayAnalytics";
import DisplayIndex from "./Components/Portfolio/DisplayIndex";
import DisplayNFT from "./Components/Portfolio/NFTPage/DisplayNFT";
import Query from "./Components/QueryPage/Query";
import TablePage from "./Components/TablePage/TablePage"; // Import TablePage component

import CheckoutSuccess from "./Components/Payment/CheckoutSuccess";
import MasterAgreement from "./Components/Legal/MasterAgreement";
import Privacy from "./Components/Legal/Privacy";
import TermsAndConditions from "./Components/Legal/TermsAndConditions";
// import Alerts from "./Components/Alerts/Alerts";
//import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import Index from "./Components/Alerts/Index";
import Explore from "./Components/Explore";
import MetadataProvider from "./metadataProvider";
import Glossary from "./Components/Glossary/Glossary";
import GlossaryTermPage from "./Components/Glossary/GlossaryTermPage";
import DashboardList from "./Components/Dashboard/views/DashboardList";
import DashboardView from "./Components/Dashboard/views/DashboardView";

// default query client settings
const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      refetchOnmount: false,
      refetchOnReconnect: false,
      staleTime: 1000 * 60 * 10,
    },
  },
});

// main router for the whole website
const router = createBrowserRouter(
  createRoutesFromElements(
    <Route path="/" element={<Root />} errorElement={<ErrorBoundary />}>
      <Route index element={<Homepage />} />
      <Route path="/twitter" element={<Homepage />} />
      <Route path="/github" element={<Homepage />} />
      <Route path="/Explore" element={<Explore />}>
        <Route index element={<Charting />} />
        <Route path="Tables" element={<TablePage />} />
      </Route>
      <Route path="/Pricing" element={<Payment />} />
      <Route path="/SignUp" element={<SignUp />} />
      <Route path="/SignIn" element={<SignIn />} />;
      <Route path="/Glossary" element={<Glossary />} />
      <Route path="/Glossary/:term" element={<GlossaryTermPage />} />
      <Route path="/SignIn/:confirmationCode" element={<SignIn />} />;
      <Route path="/UserSettings" element={<UserSettings />} />;
      <Route path="/Faq" element={<Faq />} />;
      <Route path="/Discover" element={<News />} />;
      <Route path="/Dashboard" element={<Dashboard />}>
        <Route index element={<DashboardList />} />
        <Route path=":uniqueCode" element={<DashboardView />} />
      </Route>
      <Route path="/Alerts" element={<Index />} />;
      <Route path="/Reset/:confirmationCode" element={<ResetPass />} />
      <Route path="/ForgotPassword" element={<ForgotPass />} />
      <Route path="/Legal">
        <Route index element={<Legal />} />
        <Route path="/Legal/Master" element={<MasterAgreement />} />
        <Route path="/Legal/Privacy" element={<Privacy />} />
        <Route path="/Legal/Terms" element={<TermsAndConditions />} />
      </Route>
      <Route path="/Contact" element={<Contact />} />
      <Route path="/Footprint" element={<Portfolio />}>
        <Route index element={<DisplayIndex />} />
        <Route path="Transactions" element={<DisplayTransactions />} />
        <Route path="Analytics" element={<DisplayAnalytics />} />
        <Route path="Nfts" element={<DisplayNFT />} />
        <Route path="Networkgraph" element={<DisplayNetworkGraph />} />
        <Route path="Portfolio" element={<DisplayPortfolio />} />

        {/* Define other nested routes for each tab here like what launder s=aid */}
      </Route>
      <Route path="/CheckoutSuccess" element={<CheckoutSuccess />} />
      <Route path="/Query" element={<Query />} />
      {/* <Route path="/Explore/Tables" element={<TablePage />} />; */}
    </Route>
  )
);

// main error, if theres an error should end up here unless caught else where
function ErrorBoundary() {
  let error = useRouteError();
  console.error(error);
  let navigate = useNavigate();
  // Uncaught ReferenceError: path is not defined
  return (
    <main className="grid min-h-full place-items-center py-24 px-6 sm:py-32 lg:px-8 font-roboto">
      <div className="text-center">
        <h1 className="mt-4 text-3xl font-bold tracking-tight text-gray-900 sm:text-5xl">
          Page not found
        </h1>
        <p className="mt-6 text-base leading-7 text-gray-600">
          Sorry, we couldn’t find the page you’re looking for.
        </p>
        <div className="mt-6 flex items-center justify-center gap-x-6">
          <button
            onClick={() => {
              navigate("/");
            }}
            className="rounded-lg bg-primaryContainer px-3 py-2 text-sm font-semibold text-black shadow-sm hover:text-white hover:bg-primary"
          >
            Go back home
          </button>
        </div>
      </div>
    </main>
  );
}

// instatiates the client provider
export function App() {
  return (
    <QueryClientProvider client={queryClient}>
      <MetadataProvider>
        <RouterProvider router={router} />

      </MetadataProvider>
    </QueryClientProvider>
  );
}
