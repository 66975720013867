import { useState } from "react";
import { Button } from "../../Ui/button";
import { useStoreDashboard } from "../state/useStoreDashboard";
import { useStoreDialog } from "../state/useStoreDialogs";
import SingleChartSettings from "./SingleChartSettings";
import { cn } from "../../../utils/utils";
import BaseChart from "../widgets/BaseChart";
import { PlusIcon } from "@heroicons/react/24/outline";
import UUIDGen from "../../../utils/UUIDGen";
import { Tooltip } from "react-tooltip";
import DialogHeader from "../Dialogs/DialogHeader";

const generateChartData = () =>
  Array.from({ length: 5 }, (_, i) => [
    Date.now() - (4 - i) * 24 * 60 * 60 * 1000,
    Math.floor(Math.random() * 40) + 1,
  ]);

export default function ChartingInformation({
  editMode = false,
  initialData = null,
}) {
  // console.log("ChartingInformation received:", { editMode, initialData });
  const goHome = useStoreDialog((state) => state.goHome);
  const add = useStoreDashboard((state) => state.addItem);
  const updateItem = useStoreDashboard((state) => state.updateItem);

  // Initialize first chart
  const [charts, setCharts] = useState(() => {
    // console.log(
    //   "Initializing charts with data:",
    //   JSON.parse(JSON.stringify(initialData))
    // );
    if (editMode && initialData) {
      if (Array.isArray(initialData.props)) {
        const mappedCharts = initialData.props.map((chart) => ({
          ...chart,
          id: chart.id || UUIDGen(),
          widgetId: initialData.widgetId,
        }));
        // console.log("Mapped charts:", JSON.parse(JSON.stringify(mappedCharts)));
        return mappedCharts;
      }
      // Return default chart if props structure doesn't match
      return [
        {
          id: UUIDGen(),
          widgetId: initialData.widgetId, // Store widget ID from initialData
          blockchain: null,
          name: null,
          source: null,
          resolution: null,
          style: null,
          colour: "#FA9173",
          data: generateChartData(),
        },
      ];
    }
    // Default new chart
    return [
      {
        id: UUIDGen(),
        blockchain: null,
        name: null,
        source: null,
        resolution: null,
        style: null,
        colour: "#FA9173",
        data: generateChartData(),
      },
    ];
  });

  const handleAddChart = () => {
    const newID = UUIDGen();
    setActive(newID);
    setCharts((prev) => [
      ...prev,
      {
        id: newID,
        blockchain: null,
        name: null,
        source: null,
        resolution: null,
        style: null,
        colour: "#FA9173",
        data: generateChartData(),
      },
    ]);
  };
  const handleRemoveChart = (chartId) => {
    setCharts((prev) => {
      const newCharts = prev.filter((chart) => chart.id !== chartId);
      // If we're removing the active chart, set active to the first remaining chart
      if (active === chartId && newCharts.length > 0) {
        setActive(newCharts[0].id);
      }
      return newCharts;
    });
  };
  // console.log("Charts state after initialization:", charts);
  const [active, setActive] = useState(charts[0]?.id);

  const transformedData = charts
    .filter((chart) => chart?.style && chart?.colour)
    .map((chart) => ({
      data: chart.data,
      colour: chart.colour,
      style: { data: chart.style?.data || "line" },
    }));

  const shouldShowChart = charts.some((chart) => chart?.style && chart?.colour);

  return (
    <div className="h-full w-full">
      <DialogHeader
        title={editMode ? "Edit Chart" : "Chart"}
        onBack={goHome}
        editMode={editMode}
      />

      <div className="grid grid-cols-2 h-[540px]">
        <div className="col-span-1 overflow-y-scroll">
          {charts.map((item, index) => (
            <div
              key={item.id}
              className={cn(
                active != item.id
                  ? "h-12 overflow-hidden border-b"
                  : "h-[520px]",
                "transition-all duration-100 px-4 py-2"
              )}
            >
              <SingleChartSettings
                id={item.id}
                charts={charts}
                setCharts={setCharts}
                active={active}
                setActive={setActive}
                index={index}
                onRemove={
                  charts.length > 1
                    ? () => handleRemoveChart(item.id)
                    : undefined
                }
              />
            </div>
          ))}
        </div>

        <div className="col-span-1 flex items-center justify-center font-monda">
          {!shouldShowChart ? (
            <div className="text-muted-foreground">
              Configure chart settings to preview
            </div>
          ) : (
            <BaseChart
              data={transformedData}
              minimal={true}
              showControls={false}
              height="350px"
              width="350px"
            />
          )}
        </div>
      </div>

      <div className="flex justify-end border-t pt-4 relative">
        <button
          className="absolute -top-[30px] left-48 group p-2 res-select rounded-full bg-surfaceVariant hover:bg-primary shadow-md border border-primaryContainer transition-all"
          onClick={handleAddChart}
        >
          <PlusIcon className="h-10 w-10 text-onSurfaceVariant group-hover:text-white" />
        </button>
        <Tooltip anchorSelect=".res-select" place="top">
          Add another data set
        </Tooltip>
        <Button
          variant="outline"
          onClick={() => {
            if (editMode) {
              console.log(
                "Current chart state before update:",
                JSON.parse(JSON.stringify(charts))
              );
              console.log("Updating chart widget:", {
                widgetId: initialData.widgetId,
                chartData: charts.map((chart) => ({
                  blockchain: chart.blockchain,
                  name: chart.name,
                  resolution: chart.resolution,
                  style: chart.style,
                  colour: chart.colour,
                  data: chart.data,
                })),
              });
              updateItem(initialData.widgetId, charts);
              goHome();
            } else {
              add("Chart", 4, 4, charts);
            }
          }}
        >
          {editMode ? "Update Widget" : "Add to Dashboard"}
        </Button>
      </div>
    </div>
  );
}
