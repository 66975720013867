import React from "react";
import SmallChart from "./SmallChart";
import NewsCard from "./NewsCard";
import SingleValue from "./SingleValue";
import ChartContainer from "./ChartContainer";
import TextRenderer from "./TextRenderer";

const WidgetRenderer = ({ type, props }) => {
  console.log("WidgetRenderer rendering:", { type, props });
  switch (type) {
    case "Chart":
      return <ChartContainer charts={props} />;

    case "Text":
      return (
        <TextRenderer
          key={props?.lastModified}
          content={props?.content || ""}
        />
      );

    case "Small":
      return <SmallChart />;

    case "News":
      return (
        <NewsCard
          title={props?.title ?? ""}
          link={props?.link ?? "#"}
          author={props?.author ?? ""}
          published={props?.published ?? Date.now()}
          image={props?.media_content ?? []}
        />
      );

    case "Value":
      return (
        <SingleValue
          name={props?.name ?? "Value"}
          prefix={props?.prefix ?? "$"}
          decimals={props?.decimals ?? 2}
          value={props?.value ?? 0}
        />
      );

    default:
      return <div>Unsupported widget type: {type}</div>;
  }
};

export default WidgetRenderer;
