import { useEditorState } from "@tiptap/react";
import {
  Bold,
  Italic,
  AlignLeft,
  AlignCenter,
  AlignRight,
  Code,
  Heading1,
  Heading2,
  Heading3,
  Heading4,
} from "lucide-react";
import { cn } from "../../../utils/utils";

const CHARACTER_LIMIT = 2000;

const EditorToolbar = ({ editor }) => {
  const editorState = useEditorState({
    editor,
    selector: ({ editor }) => ({
      isBold: editor.isActive("bold"),
      isItalic: editor.isActive("italic"),
      isCode: editor.isActive("codeBlock"),
      alignment: editor.getAttributes("paragraph").textAlign || "left",
      characterCount: editor.getText().length,
      heading: {
        1: editor.isActive("heading", { level: 1 }),
        2: editor.isActive("heading", { level: 2 }),
        3: editor.isActive("heading", { level: 3 }),
        4: editor.isActive("heading", { level: 4 }),
      },
    }),
  });

  const { isBold, isItalic, isCode, alignment, characterCount, heading } =
    editorState;

  const buttonClass = (isActive) =>
    cn(
      "h-8 w-8 p-0 border border-primaryContainer group transition-colors shadow-none inline-flex items-center justify-center whitespace-nowrap shadow-md rounded-md text-sm font-medium ring-offset-background transition-colors focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:pointer-events-none disabled:opacity-50",
      isActive
        ? "bg-primary text-white"
        : "hover:bg-primary hover:text-white text-onSurfaceVariant"
    );

  const handleAlignment = (align) => {
    if (alignment === align) {
      editor.chain().focus().setTextAlign("left").run();
    } else {
      editor.chain().focus().setTextAlign(align).run();
    }
  };

  return (
    <div className="border-b bg-background px-4 py-2 flex justify-between items-center shrink-0">
      <div className="flex items-center gap-1 overflow-x-auto">
        <div className="flex items-center gap-1">
          <button
            onClick={() => editor.chain().focus().toggleBold().run()}
            disabled={!editor.can().chain().focus().toggleBold().run()}
            className={buttonClass(isBold)}
          >
            <Bold className="h-4 w-4" />
          </button>
          <button
            onClick={() => editor.chain().focus().toggleItalic().run()}
            disabled={!editor.can().chain().focus().toggleItalic().run()}
            className={buttonClass(isItalic)}
          >
            <Italic className="h-4 w-4" />
          </button>
        </div>

        <div className="w-px h-6 bg-border mx-2" />

        <div className="flex items-center gap-1">
          {[1, 2, 3, 4].map((level) => (
            <button
              key={level}
              onClick={() =>
                editor.chain().focus().toggleHeading({ level }).run()
              }
              className={buttonClass(heading[level])}
            >
              {level === 1 && <Heading1 className="h-4 w-4" />}
              {level === 2 && <Heading2 className="h-4 w-4" />}
              {level === 3 && <Heading3 className="h-4 w-4" />}
              {level === 4 && <Heading4 className="h-4 w-4" />}
            </button>
          ))}
        </div>

        <div className="w-px h-6 bg-border mx-2" />

        <div className="flex items-center gap-1">
          <button
            onClick={() => handleAlignment("left")}
            className={buttonClass(alignment === "left" || !alignment)}
          >
            <AlignLeft className="h-4 w-4" />
          </button>
          <button
            onClick={() => handleAlignment("center")}
            className={buttonClass(alignment === "center")}
          >
            <AlignCenter className="h-4 w-4" />
          </button>
          <button
            onClick={() => handleAlignment("right")}
            className={buttonClass(alignment === "right")}
          >
            <AlignRight className="h-4 w-4" />
          </button>
        </div>

        <div className="w-px h-6 bg-border mx-2" />

        <div className="flex items-center gap-1">
          <button
            onClick={() => editor.chain().focus().toggleCodeBlock().run()}
            className={buttonClass(isCode)}
          >
            <Code className="h-4 w-4" />
          </button>
        </div>
      </div>

      <div
        className={cn(
          "text-sm",
          characterCount > CHARACTER_LIMIT
            ? "text-destructive"
            : "text-muted-foreground"
        )}
      >
        {characterCount}/{CHARACTER_LIMIT}
      </div>
    </div>
  );
};

export default EditorToolbar;
