import { cn } from "../../../utils/utils";

const DialogHeader = ({ title, onBack, editMode = false }) => {
  return (
    <div
      onClick={!editMode ? onBack : undefined}
      className={cn(
        "border-b py-3 text-gray-800 flex justify-between px-2 w-full",
        !editMode && "group hover:text-primary transition-colors cursor-pointer"
      )}
    >
      <div className="font-semibold text-xl">{title}</div>
    </div>
  );
};

export default DialogHeader;
