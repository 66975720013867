import { useStoreDialog } from "../state/useStoreDialogs";
import DialogHeader from "./DialogHeader";

export default function Table() {
  const navTo = useStoreDialog((state) => state.navTo);
  const goHome = useStoreDialog((state) => state.goHome);
  const editMode = false;

  return (
    <div className="h-full w-full bg-indigo-50">
      <DialogHeader
        title={editMode ? "Edit Table" : "Table"}
        onBack={goHome}
        editMode={editMode}
      />
      <span>Add table editor functionality here</span>
    </div>
  );
}
