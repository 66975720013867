import React, { useMemo } from "react";
import { useNavigate } from "react-router-dom";
import BaseChart from "./BaseChart";
import { useChartQuery } from "../API/useChartQuery";
import { Loader2, LogIn } from "lucide-react";
import { isAuthenticated } from "../../../utils/auth";
import { Button } from "../../Ui/button";

export default function ChartContainer({ charts }) {
  const navigate = useNavigate();
  const isLoggedIn = isAuthenticated();

  // Only include data-related properties in query key
  const queryKey = useMemo(() => {
    if (!Array.isArray(charts)) return [];
    return charts.map((chart) => [
      chart.id,
      chart.blockchain?.name,
      chart.resolution,
      chart.name?.name,
      chart.source,
    ]);
  }, [charts]);

  // Get data from API
  const { results } = isLoggedIn
    ? useChartQuery(charts, queryKey)
    : { results: [] };

  // Apply visual properties to results
  const transformedResults = useMemo(() => {
    return results.map((result, index) => {
      const chart = charts[index];
      if (!chart || !result.data) return result;

      return {
        ...result,
        colour: chart.colour,
        style: chart.style,
      };
    });
  }, [results, charts]);

  if (!isLoggedIn) {
    return (
      <div className="flex flex-col items-center justify-center h-full space-y-4 p-6 bg-gray-50 rounded-lg border-2 border-dashed border-gray-300">
        <div className="text-center space-y-2">
          <h3 className="font-semibold text-gray-900">
            Authentication Required
          </h3>
          <p className="text-sm text-gray-600">
            Please log in to view this chart data
          </p>
        </div>
        <Button onClick={() => navigate("/signin")} className="gap-2">
          <LogIn className="h-4 w-4" />
          Login to View
        </Button>
      </div>
    );
  }

  if (results.some((items) => items.isLoading)) {
    return (
      <div className="flex items-center justify-center h-full">
        <Loader2 className="h-8 w-8 animate-spin text-primary" />
      </div>
    );
  }

  if (results.some((items) => items.isError)) {
    return (
      <div className="flex items-center justify-center h-full text-destructive">
        Error loading chart data
      </div>
    );
  }

  return (
    <BaseChart
      data={transformedResults}
      watermark={true}
      minimal={false}
      showControls={true}
    />
  );
}
