/* eslint-disable react/no-unescaped-entities */
import React, { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { useToast } from "../../Ui/use-toast";
import { Globe2, Loader2, Lock, Heart } from "lucide-react";
import { Button } from "../../Ui/button";
import {
  isAuthenticated,
  hasEditPermission,
  canAccessDashboard,
} from "../../../utils/auth";
import {
  useDashboard,
  useDashboardMutation,
  useToggleDashboardPublic,
  useDashboardLike,
} from "../API/useDashboardQueries";
import { useStoreDashboard } from "../state/useStoreDashboard";
import DashboardLayout from "../layout/DashboardLayout";
import WidgetRenderer from "../widgets/WidgetRenderer";
import DashboardDialog from "../Dialogs/DashboardDialog";
import {
  AlertDialog,
  AlertDialogAction,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
} from "../../Ui/AlertDialog";
import { Switch } from "../../Ui/switch";
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from "../../Ui/ToolTip";
import { cn } from "../../../utils/utils";
import UUIDGen from "../../../utils/UUIDGen";

function DashboardView() {
  const { uniqueCode } = useParams();
  const navigate = useNavigate();
  const { toast } = useToast();

  const [edit, setEdit] = useState(false);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [editingWidget, setEditingWidget] = useState(null);
  const [showSaveError, setShowSaveError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [showVisibilityConfirm, setShowVisibilityConfirm] = useState(false);
  const [pendingVisibilityChange, setPendingVisibilityChange] = useState(false);

  // Local store state
  const layout = useStoreDashboard((state) => state.layouts);
  const items = useStoreDashboard((state) => state.items);
  const remove = useStoreDashboard((state) => state.removeItem);
  const updateLayout = useStoreDashboard((state) => state.updateLayout);
  const dashboardName = useStoreDashboard((state) => state.dashboardName);
  const setDashboardName = useStoreDashboard((state) => state.setDashboardName);
  const setDashboardState = useStoreDashboard(
    (state) => state.setDashboardState
  );
  const removeAll = useStoreDashboard((state) => state.removeAll);

  const {
    data: dashboardData,
    error: dashboardError,
    isLoading,
  } = useDashboard(uniqueCode);
  const isLoggedIn = isAuthenticated();

  const { mutate: saveDashboard, isLoading: isSaving } = useDashboardMutation({
    onSuccess: () => {
      toast({
        variant: "successV2",
        title: "Dashboard saved successfully",
      });
    },
    onError: (error) => {
      setErrorMessage(error.message || "Failed to save dashboard");
      setShowSaveError(true);
    },
  });
  const { mutate: toggleVisibility } = useToggleDashboardPublic({
    onSuccess: () => {
      toast({
        variant: "successV2",
        title: "Dashboard visibility updated",
      });
    },
    onError: (error) => {
      toast({
        variant: "destructive",
        title: "Failed to update visibility",
        description: error.message || "Please try again",
      });
    },
  });

  const { mutate: toggleLike, isLoading: isLikeLoading } = useDashboardLike({
    onError: (error) => {
      toast({
        variant: "destructive",
        title: "Error updating like status",
        description: error.message || "Please try again",
      });
    },
  });

  useEffect(() => {
    if (dashboardData?.data) {
      const canAccess = canAccessDashboard(dashboardData.data);
      if (!canAccess) {
        navigate("/dashboard", {
          replace: true,
          state: { error: "You don't have permission to view this dashboard" },
        });
        return;
      }

      // Update local state with dashboard data
      const { items, layout, dashboardName } = dashboardData.data;
      removeAll();
      setDashboardState(items, layout, dashboardName);

      // Check edit permissions
      const canEdit = hasEditPermission(dashboardData.data);
      setEdit(false); // Reset edit mode
      if (!canEdit) {
        toast({
          variant: "default",
          title: "View Only",
          description: "You have view-only access to this dashboard",
        });
      }
    }
  }, [dashboardData, navigate, removeAll, setDashboardState]);

  const handleSave = () => {
    // Get the current dashboard data including permissions
    const currentData = dashboardData?.data;

    const saveData = {
      dashboardName,
      layout,
      items,
      // Preserve existing permissions or set defaults if none exist
      permissions: currentData?.permissions || {
        sharedWith: [],
        accessLevel: "edit",
      },
      // Preserve other fields that should persist
      isPublic: currentData?.isPublic ?? false,
      version: currentData?.version ?? 1,
      status: currentData?.status ?? "active",
    };

    saveDashboard({
      uniqueCode,
      data: saveData,
    });
  };
  const handleVisibilityToggle = (newVisibility) => {
    if (!uniqueCode) return;

    // If trying to make public, show confirmation
    if (newVisibility) {
      setPendingVisibilityChange(true);
      setShowVisibilityConfirm(true);
    } else {
      // If making private, do it immediately
      toggleVisibility({
        uniqueCode,
        isPublic: false,
      });
    }
  };

  const handleConfirmPublic = () => {
    toggleVisibility({
      uniqueCode,
      isPublic: true,
    });
    setShowVisibilityConfirm(false);
  };
  const handleEditWidget = (widgetId, widgetData) => {
    console.log("Full widget data:", {
      widgetId,
      type: widgetData.type,
      props: JSON.parse(JSON.stringify(widgetData.props)), // Deep copy to see full structure
    });
    setEditingWidget({
      widgetId,
      type: widgetData.type,
      props: widgetData.props,
    });
    setDialogOpen(true);
  };

  const renderHeaderExtras = () => {
    return (
      <>
        {/* Like Button */}
        {isLoggedIn && (
          <TooltipProvider>
            <Tooltip>
              <TooltipTrigger asChild>
                <button
                  className={cn(
                    "h-10 w-10 inline-flex group items-center justify-center whitespace-nowrap ring-offset-background transition-colors focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:pointer-events-none disabled:opacity-50",
                    dashboardData?.data?.likedBy?.includes(
                      JSON.parse(localStorage.getItem("user")).id
                    ) && "text-primary"
                  )}
                  onClick={() => {
                    if (!isLoggedIn) {
                      toast({
                        title: "Authentication Required",
                        description: "Please log in to like dashboards",
                        variant: "destructive",
                      });
                      return;
                    }

                    const isCurrentlyLiked =
                      dashboardData?.data?.likedBy?.includes(
                        JSON.parse(localStorage.getItem("user")).id
                      );

                    toggleLike({
                      uniqueCode,
                      isLiking: !isCurrentlyLiked,
                    });
                  }}
                  disabled={isLikeLoading}
                >
                  {isLikeLoading ? (
                    <Loader2 className="h-5 w-5 animate-spin" />
                  ) : (
                    <Heart
                      className={cn(
                        "h-5 w-5 text-onSurfaceVariant group-hover:text-white ",
                        dashboardData?.data?.likedBy?.includes(
                          JSON.parse(localStorage.getItem("user")).id
                        ) && "fill-primary group-hover:fill-none",
                        !dashboardData?.data?.likedBy?.includes(
                          JSON.parse(localStorage.getItem("user")).id
                        ) && "group-hover:fill-primary"
                      )}
                    />
                  )}
                </button>
              </TooltipTrigger>
              <TooltipContent>
                {dashboardData?.data?.likedBy?.includes(
                  JSON.parse(localStorage.getItem("user")).id
                )
                  ? "Unlike"
                  : "Like"}{" "}
                this dashboard
              </TooltipContent>
            </Tooltip>
          </TooltipProvider>
        )}

        {/* Visibility Toggle Section - Updated styles */}
        {canEdit && (
          <div className="flex items-center gap-2">
            {dashboardData?.data?.isPublic ? (
              <Globe2 className="h-4 w-4 text-primary" />
            ) : (
              <Lock className="h-4 w-4 text-primary" />
            )}
            <TooltipProvider>
              <Tooltip delayDuration={300}>
                <TooltipTrigger asChild>
                  <div className="flex items-center gap-2">
                    <Switch
                      checked={dashboardData?.data?.isPublic}
                      onCheckedChange={handleVisibilityToggle}
                      disabled={isSaving}
                      aria-label="Toggle dashboard visibility"
                    />
                    <span className="text-sm text-muted-foreground">
                      {dashboardData?.data?.isPublic ? "Public" : "Private"}
                    </span>
                  </div>
                </TooltipTrigger>
                <TooltipContent side="bottom">
                  <p>
                    {dashboardData?.data?.isPublic
                      ? "Anyone with the link can view this dashboard"
                      : "Only you can access this dashboard"}
                  </p>
                </TooltipContent>
              </Tooltip>
            </TooltipProvider>
          </div>
        )}
      </>
    );
  };

  if (isLoading) {
    return (
      <div className="flex items-center justify-center h-full">
        <div className="flex flex-col items-center gap-4">
          <Loader2 className="h-8 w-8 animate-spin text-primary" />
          <p className="text-muted-foreground">Loading dashboard...</p>
        </div>
      </div>
    );
  }

  if (dashboardError?.response?.status === 404) {
    return (
      <div className="flex flex-col items-center justify-center h-full gap-4">
        <h2 className="text-2xl font-bold">Dashboard Not Found</h2>
        <p className="text-gray-600">
          This dashboard doesn't exist or has been deleted.
        </p>
        <Button onClick={() => navigate("/dashboard")}>
          Return to Dashboards
        </Button>
      </div>
    );
  }

  if (dashboardError?.response?.status === 403) {
    return (
      <div className="flex flex-col items-center justify-center h-full gap-4">
        <h2 className="text-2xl font-bold">Access Denied</h2>
        <p className="text-gray-600">
          You don't have permission to view this dashboard.
        </p>
        {!isLoggedIn && (
          <Button onClick={() => navigate("/signin")}>
            Log In to Continue
          </Button>
        )}
        <Button variant="outline" onClick={() => navigate("/dashboard")}>
          Return to Dashboards
        </Button>
      </div>
    );
  }

  if (dashboardError) {
    return (
      <div className="flex flex-col items-center justify-center h-full gap-4">
        <h2 className="text-2xl font-bold text-red-500">
          Error Loading Dashboard
        </h2>
        <p className="text-gray-600">{dashboardError.message}</p>
        <Button onClick={() => navigate("/dashboard")}>
          Return to Dashboards
        </Button>
      </div>
    );
  }

  const canEdit = dashboardData?.data
    ? hasEditPermission(dashboardData.data)
    : false;

  return (
    <>
      <AlertDialog open={showSaveError} onOpenChange={setShowSaveError}>
        <AlertDialogContent>
          <AlertDialogHeader>
            <AlertDialogTitle>Error Saving Dashboard</AlertDialogTitle>
            <AlertDialogDescription>{errorMessage}</AlertDialogDescription>
          </AlertDialogHeader>
          <AlertDialogFooter>
            <AlertDialogAction onClick={() => setShowSaveError(false)}>
              OK
            </AlertDialogAction>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialog>
      <AlertDialog
        open={showVisibilityConfirm}
        onOpenChange={setShowVisibilityConfirm}
      >
        <AlertDialogContent>
          <AlertDialogHeader>
            <AlertDialogTitle>Make Dashboard Public?</AlertDialogTitle>
            <AlertDialogDescription>
              Making this dashboard public will allow anyone with the link to
              view it. They won't be able to edit the dashboard, but they can
              see all the data and widgets. Are you sure you want to continue?
            </AlertDialogDescription>
          </AlertDialogHeader>
          <AlertDialogFooter>
            <AlertDialogCancel
              onClick={() => {
                setShowVisibilityConfirm(false);
                setPendingVisibilityChange(false);
              }}
            >
              Cancel
            </AlertDialogCancel>
            <AlertDialogAction onClick={handleConfirmPublic}>
              Make Public
            </AlertDialogAction>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialog>
      <DashboardLayout
        dashboardName={dashboardName}
        onDashboardNameChange={canEdit ? setDashboardName : undefined}
        layout={layout}
        items={items}
        onLayoutChange={canEdit ? updateLayout : undefined}
        onRemoveWidget={canEdit ? remove : undefined}
        isEditing={edit && canEdit}
        onToggleEdit={canEdit ? () => setEdit(!edit) : undefined}
        isSaving={isSaving}
        onSave={canEdit ? handleSave : undefined}
        onOpenDialog={canEdit ? () => setDialogOpen(true) : undefined}
        onEdit={handleEditWidget}
        showEditControls={canEdit}
        headerExtras={renderHeaderExtras()}
      >
        {(type, props) => <WidgetRenderer type={type} props={props} />}
      </DashboardLayout>

      {canEdit && (
        <DashboardDialog
          open={dialogOpen}
          setOpen={(open) => {
            setDialogOpen(open);
            if (!open) setEditingWidget(null);
          }}
          editMode={!!editingWidget}
          initialData={editingWidget}
        />
      )}
    </>
  );
}

export default DashboardView;
