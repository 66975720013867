import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Input } from "../../Ui/input";
import { ScrollArea } from "../../Ui/ScrollArea";
import {
  LayoutDashboard,
  PlusIcon,
  ArrowLeftIcon,
  LogIn,
  Clock,
  TrendingUp,
  Heart,
} from "lucide-react";
import { cn } from "../../../utils/utils";
import {
  useUserDashboards,
  usePopularDashboards,
  useLikedDashboards,
} from "../API/useDashboardQueries";
import { Button } from "../../Ui/button";
import { isAuthenticated } from "../../../utils/auth";
import { useStoreRecent } from "../state/useStoreRecent";

const DashboardSection = ({
  title,
  icon: Icon,
  dashboards,
  isCollapsed,
  searchTerm,
  onSelect,
}) => {
  if (!dashboards?.length) return null;

  const filteredDashboards = dashboards.filter((dashboard) =>
    dashboard.dashboardName.toLowerCase().includes(searchTerm.toLowerCase())
  );

  if (!filteredDashboards.length) return null;

  return (
    <div className="space-y-2 mb-4">
      {!isCollapsed && (
        <h3 className="font-medium text-gray-900 px-2 text-sm">{title}</h3>
      )}
      {filteredDashboards.map((dashboard) => (
        <button
          key={dashboard.uniqueCode}
          onClick={() => onSelect(dashboard)}
          className={cn(
            "flex place-items-center bg-white rounded-lg font-medium border border-primaryContainer hover:bg-primary hover:text-white group transition-colors",
            isCollapsed ? "w-10 h-10 mx-auto justify-center" : "w-full p-2"
          )}
        >
          <div className={cn("flex items-center", !isCollapsed && "w-full")}>
            <Icon
              className={cn(
                "h-5 w-5 text-onSurfaceVariant group-hover:text-white",
                !isCollapsed && "mr-2"
              )}
            />
            {!isCollapsed && (
              <span className="text-base truncate">
                {dashboard.dashboardName}
              </span>
            )}
          </div>
        </button>
      ))}
    </div>
  );
};

const DashboardSidebar = ({
  sidebar,
  setSidebar,
  onDashboardSelect,
  isTransitioning,
}) => {
  const [searchTerm, setSearchTerm] = useState("");
  const navigate = useNavigate();
  const isLoggedIn = isAuthenticated();

  const { data: userDashboards } = useUserDashboards();
  const { data: popularDashboards } = usePopularDashboards();
  const { data: likedDashboards } = useLikedDashboards();
  const recentDashboards = useStoreRecent((state) => state.recentDashboards);

  const handleDashboardSelect = (dashboard) => {
    onDashboardSelect(dashboard);
  };

  return (
    <div
      className={cn(
        "h-[95vh] pb-4 transition-all font-roboto bg-surface transform border-r border-primaryContainer",
        sidebar ? "w-[248px] px-1" : "w-16"
      )}
    >
      {/* Header with toggle and search */}
      <div className="flex flex-row px-2 mx-auto py-2">
        <button
          onClick={() => setSidebar(!sidebar)}
          className={cn(
            "bg-surfaceVariant rounded-lg flex items-center justify-center border group border-primaryContainer hover:bg-primary hover:text-white transition-colors",
            sidebar ? "mr-2 w-10 h-10" : "w-10 h-10 mx-auto"
          )}
          disabled={isTransitioning}
        >
          <ArrowLeftIcon
            className={cn(
              "h-5 w-5 group-hover:text-white text-onSurfaceVariant",
              !sidebar && "rotate-180",
              "transition duration-75"
            )}
          />
        </button>
        {sidebar && (
          <div className="relative w-full">
            <Input
              type="text"
              placeholder="Search dashboards"
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
              className="w-full px-4 h-12 rounded-xl border border-primaryContainer focus:border-primary focus:outline-none"
              disabled={isTransitioning}
            />
          </div>
        )}
      </div>

      {/* Add New Dashboard Button - Only for logged in users */}
      {isLoggedIn && (
        <div className="px-2 pt-2 pb-1">
          <button
            onClick={() => onDashboardSelect(null)}
            className={cn(
              "flex place-items-center bg-surfaceVariant border border-primaryContainer hover:bg-primary hover:text-white group transition-colors rounded-lg font-medium",
              sidebar
                ? "w-full p-2 justify-between"
                : "w-10 h-10 mx-auto justify-center"
            )}
            disabled={isTransitioning}
          >
            <div className={cn("flex items-center", sidebar && "w-full")}>
              <PlusIcon
                className={cn(
                  "h-5 w-5 text-onSurfaceVariant group-hover:text-white",
                  sidebar && "mr-2"
                )}
              />
              {sidebar && <span className="text-base">New Dashboard</span>}
            </div>
          </button>
        </div>
      )}

      {/* Scrollable Content Area */}
      <ScrollArea className="h-[calc(100%-6rem)] mt-1">
        <div className="mx-auto w-full p-2 space-y-4">
          {/* Recently Viewed Section */}
          <DashboardSection
            title="Recently Viewed"
            icon={Clock}
            dashboards={recentDashboards}
            isCollapsed={!sidebar}
            searchTerm={searchTerm}
            onSelect={handleDashboardSelect}
          />

          {/* Popular Dashboards Section */}
          <DashboardSection
            title="Popular"
            icon={TrendingUp}
            dashboards={popularDashboards?.data}
            isCollapsed={!sidebar}
            searchTerm={searchTerm}
            onSelect={handleDashboardSelect}
          />

          {/* Login Prompt for Non-Authenticated Users */}
          {!isLoggedIn && sidebar && (
            <div className="p-4 bg-surfaceVariant rounded-lg border border-primaryContainer space-y-4">
              <h3 className="font-medium text-lg text-gray-900">See More</h3>
              <p className="text-sm text-gray-600">
                Log in to access your personal dashboards and likes.
              </p>
              <div className="space-y-2">
                <Button
                  onClick={() => navigate("/signin")}
                  className="w-full gap-2"
                >
                  <LogIn className="h-4 w-4" />
                  Login
                </Button>
                <Button
                  variant="outline"
                  onClick={() => navigate("/signup")}
                  className="w-full"
                >
                  Create Account
                </Button>
              </div>
            </div>
          )}

          {/* Sections Only Visible to Logged-In Users */}
          {isLoggedIn && (
            <>
              <DashboardSection
                title="Liked"
                icon={Heart}
                dashboards={likedDashboards?.data}
                isCollapsed={!sidebar}
                searchTerm={searchTerm}
                onSelect={handleDashboardSelect}
              />

              <DashboardSection
                title="My Dashboards"
                icon={LayoutDashboard}
                dashboards={userDashboards?.data}
                isCollapsed={!sidebar}
                searchTerm={searchTerm}
                onSelect={handleDashboardSelect}
              />
            </>
          )}
        </div>
      </ScrollArea>
    </div>
  );
};

export default DashboardSidebar;
