import { useState } from "react";
import { Outlet, useNavigate, useParams } from "react-router-dom";
import DashboardSidebar from "./views/DashboardSidebar";
import { useStoreDashboard } from "./state/useStoreDashboard";
import { useToast } from "../Ui/use-toast";
import { Loader2 } from "lucide-react";
import { isAuthenticated } from "../../utils/auth";
import CreateDashboardDialog from "./Dialogs/CreateDashboardDialog";
import { useStoreRecent } from "./state/useStoreRecent";

function Index() {
  const [sidebar, setSidebar] = useState(true);
  const [isTransitioning, setIsTransitioning] = useState(false);
  const [createDialogOpen, setCreateDialogOpen] = useState(false);
  const removeAll = useStoreDashboard((state) => state.removeAll);
  const { toast } = useToast();
  const navigate = useNavigate();
  const { uniqueCode } = useParams();
  const isLoggedIn = isAuthenticated();
  const addRecentDashboard = useStoreRecent(
    (state) => state.addRecentDashboard
  );

  const handleDashboardSelect = async (dashboard) => {
    try {
      setIsTransitioning(true);
      // Clear existing state
      removeAll();

      if (dashboard === null) {
        if (!isLoggedIn) {
          toast({
            variant: "destructive",
            title: "Authentication Required",
            description: "Please log in to create a new dashboard",
          });
          navigate("/signin");
          return;
        }

        // Open create dialog
        setCreateDialogOpen(true);
      } else {
        // Add to recently viewed before navigation
        addRecentDashboard(dashboard);

        // Navigate to the specific dashboard using its uniqueCode
        navigate(`/dashboard/${dashboard.uniqueCode}`);
      }
    } catch (error) {
      toast({
        variant: "destructive",
        title: "Error switching dashboard",
        description: error.message || "Failed to switch dashboard",
      });
    } finally {
      setTimeout(() => {
        setIsTransitioning(false);
      }, 150);
    }
  };

  // If we're at the root dashboard path, render the DashboardList
  if (!uniqueCode) {
    return (
      <>
        <Outlet context={{ onDashboardSelect: handleDashboardSelect }} />
        <CreateDashboardDialog
          open={createDialogOpen}
          setOpen={setCreateDialogOpen}
        />
      </>
    );
  }

  return (
    <div className="w-full h-full">
      <div className="flex flex-row relative z-0">
        <div className="hidden md:block">
          <DashboardSidebar
            sidebar={sidebar}
            setSidebar={setSidebar}
            onDashboardSelect={handleDashboardSelect}
            currentDashboardId={uniqueCode}
            isTransitioning={isTransitioning}
          />
        </div>
        <div className="flex-1 transition-opacity duration-300 ease-in-out">
          {isTransitioning ? (
            <div className="flex items-center justify-center h-full">
              <div className="flex flex-col items-center gap-4">
                <Loader2 className="h-8 w-8 animate-spin text-primary" />
                <p className="text-muted-foreground">Loading dashboard...</p>
              </div>
            </div>
          ) : (
            <Outlet />
          )}
        </div>
      </div>
      <CreateDashboardDialog
        open={createDialogOpen}
        setOpen={setCreateDialogOpen}
      />
    </div>
  );
}

export default Index;
