// utils/auth.js

export const getToken = () => {
  if (localStorage.getItem("user") != null) {
    return JSON.parse(localStorage.getItem("user")).accessToken;
  }
  return "";
};

export const isAuthenticated = () => {
  const user = localStorage.getItem("user");
  return user !== null;
};

export const getCurrentUser = () => {
  const user = localStorage.getItem("user");
  if (user) {
    return JSON.parse(user);
  }
  return null;
};

export const hasEditPermission = (dashboard) => {
  const currentUser = getCurrentUser();
  if (!currentUser) return false;

  // User owns the dashboard
  if (dashboard.userID === currentUser.id) return true;

  // Check shared permissions
  const sharedPermission = dashboard.permissions?.sharedWith?.find(
    (userId) => userId === currentUser.id
  );

  return sharedPermission && dashboard.permissions.accessLevel === "edit";
};

export const canAccessDashboard = (dashboard) => {
  const currentUser = getCurrentUser();

  // Public dashboards are accessible to all
  if (dashboard.isPublic) return true;

  // If not public, user must be authenticated
  if (!currentUser) return false;

  // User owns the dashboard
  if (dashboard.userID === currentUser.id) return true;

  // User has shared access
  return dashboard.permissions?.sharedWith?.includes(currentUser.id);
};
