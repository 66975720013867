import { create } from "zustand";

const createNewWidget = (w, h, len) => ({
  i: `widget${len + 1}`,
  x: 0,
  y: Infinity,
  w,
  h,
  minW: w,
  minH: h,
});

const createNewItem = (type, len, props) => ({
  i: `widget${len + 1}`,
  type,
  title: "",
  props:
    type === "Chart"
      ? props
      : {
          ...props,
          lastModified: new Date().toISOString(),
        },
});

export const useStoreDashboard = create((set) => ({
  // State
  items: [],
  layouts: [],
  dashboardName: "My Dashboard",

  // Actions
  setDashboardName: (name) => set({ dashboardName: name }),

  addItem: (type, w, h, props = null) =>
    set((state) => ({
      items: [...state.items, createNewItem(type, state.items.length, props)],
      layouts: [
        ...state.layouts,
        createNewWidget(w, h, state.layouts.length, type),
      ],
    })),

  removeItem: (id) =>
    set((state) => ({
      items: state.items.filter((item) => item.i !== id),
      layouts: state.layouts.filter((layout) => layout.i !== id),
    })),

  updateLayout: (layout) => set({ layouts: layout }),

  removeAll: () =>
    set({
      items: [],
      layouts: [],
      dashboardName: "My Dashboard",
    }),

  setDashboardState: (items, layouts, name) =>
    set({
      items,
      layouts,
      dashboardName: name,
    }),

  updateItem: (id, props) =>
    set((state) => {
      const newItems = state.items.map((item) => {
        if (item.i === id) {
          const updatedItem = {
            ...item,
            props:
              item.type === "Chart"
                ? props
                : {
                    ...item.props,
                    ...props,
                    lastModified: new Date().toISOString(),
                  },
          };
          return updatedItem;
        }
        return item;
      });
      return { items: newItems };
    }),

  updateWidgetTitle: (id, title) =>
    set((state) => ({
      items: state.items.map((item) =>
        item.i === id ? { ...item, title } : item
      ),
    })),
}));
