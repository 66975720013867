import { useQueries } from "@tanstack/react-query";
import request from "../../../Api/Api.js";

function getToken() {
  if (localStorage.getItem("user") != null) {
    return JSON.parse(localStorage.getItem("user")).accessToken;
  } else {
    return "";
  }
}

export function useChartQuery(charts, queryKeys) {
  async function query(blockchain, res, graphName, source, style, colour) {
    var string = source
      ? "/" + blockchain + "/" + res + "/" + graphName + "/" + source
      : "/" + blockchain + "/" + res + "/" + graphName;

    var colourCleaned = colour.replace(/^#/, "");
    string = string.concat(
      "?settings=" +
        JSON.stringify({
          style: style,
          colour: colourCleaned,
        })
    );
    const data = await request.get(string, {
      headers: {
        Authorization: `Bearer ${getToken()}`,
      },
    });

    // add the settings in here!
    // injecting data to a promise seems like a bad idea..
    // maybe can adjust the query to include a body and then return body in the query if it exists
    return data;
  }

  function genKey(id, blockchain, res, graphName, source) {
    var string = source
      ? "/" + blockchain + "/" + res + "/" + graphName + "/" + source
      : "/" + blockchain + "/" + res + "/" + graphName;
    return string.concat(id);
  }
  // can i insert meta data into the query promise when it is created?
  const results = useQueries({
    queries: Array.isArray(charts)
      ? charts.map((value, index) => {
          return {
            queryKey: queryKeys[index], // Use the memoized key
            queryFn: () =>
              query(
                value.blockchain.name,
                value.resolution,
                value.name.name,
                value?.source,
                value?.style,
                value?.colour ?? "#FA9173"
              ),
            // Add these options to ensure refresh on prop changes
            refetchOnMount: true,
            refetchOnWindowFocus: false,
          };
        })
      : [],
  });
  return { results: results };
}
