import React, { useState, useRef, useMemo, useEffect } from "react";
import Highcharts from "highcharts/highstock";
import HighchartsReact from "highcharts-react-official";
import largeWatermark from "../../../Assets/WevrWatermark.svg";
import smallWatermark from "../../../Assets/WevrLogoSecondary.svg";

const BaseChart = ({
  data,
  minimal = false,
  showControls = true,
  height = "100%",
  width = "100%",
  watermark = false,
}) => {
  const chartRef = useRef(null);

  const processData = (data) => {
    return data.map((item, index) => {
      // Handle both simple and complex data structures
      const graphData = item.data?.data?.graphData?.[0]?.values || item.data;
      return {
        id: index,
        name: item.data?.data?.name || `Series ${index + 1}`,
        data: graphData,
        color: item.colour || item.data?.data?.settings?.colour || "#FA9173",
        type:
          item.style?.data || item.data?.data?.settings?.style?.data || "line",
        yAxis: 0,
      };
    });
  };

  const [chartOptions, setChartOptions] = useState({
    exporting: {
      enabled: false,
      enableImages: true,
    },
    legend: {
      enabled: !minimal,
      itemStyle: {
        fontWeight: "bold",
        fontFamily: "Monda",
      },
    },
    chart: {
      backgroundColor: "transparent",
      style: {
        fontFamily: "Monda",
      },
      zoomType: minimal ? undefined : "x",
      events: {
        load: function () {
          if (!minimal && watermark) {
            const height_image = 127;
            const width_image = 627.98;
            const height_image_sm = 100;
            const width_image_sm = 152;
            const threshold = 800;

            const useSmallWatermark = this.plotWidth < threshold;
            const currentWatermark = useSmallWatermark
              ? smallWatermark
              : largeWatermark;
            const currentWidth = useSmallWatermark
              ? width_image_sm
              : width_image;
            const currentHeight = useSmallWatermark
              ? height_image_sm
              : height_image;

            const textX = this.plotLeft + this.plotWidth * 0.5;
            const textY = this.plotTop + this.plotHeight * 0.5;
            const position_x = textX - currentWidth / 2;
            const position_y = textY - currentHeight / 2;

            this.watermarkImg = this.renderer
              .image(
                currentWatermark,
                position_x,
                position_y,
                currentWidth,
                currentHeight
              )
              .attr({
                opacity: 0.6,
                zIndex: 0,
              })
              .add();
          }
        },
        redraw: function () {
          if (this.watermarkImg && !minimal && watermark) {
            const height_image = 127;
            const width_image = 627.98;
            const height_image_sm = 100;
            const width_image_sm = 152;
            const threshold = 800;

            const useSmallWatermark = this.plotWidth < threshold;
            const currentWatermark = useSmallWatermark
              ? smallWatermark
              : largeWatermark;
            const currentWidth = useSmallWatermark
              ? width_image_sm
              : width_image;
            const currentHeight = useSmallWatermark
              ? height_image_sm
              : height_image;

            const textX = this.plotLeft + this.plotWidth * 0.5;
            const textY = this.plotTop + this.plotHeight * 0.5;
            const position_x = textX - currentWidth / 2;
            const position_y = textY - currentHeight / 2;

            this.watermarkImg.attr({
              href: currentWatermark,
              x: position_x,
              y: position_y,
              width: currentWidth,
              height: currentHeight,
            });
          }
        },
      },
    },
    plotOptions: {
      series: {
        opacity: 0.75,
        lineWidth: minimal ? 1 : 4,
      },
    },
    scrollbar: {
      enabled: false,
    },
    xAxis: {
      visible: !minimal,
      labels: {
        style: {
          fontSize: "13px",
          fontFamily: "Monda",
        },
      },
    },
    yAxis: {
      visible: !minimal,
      opposite: false,
      labels: {
        style: {
          fontSize: "13px",
          fontFamily: "Monda",
        },
      },
    },
    credits: {
      enabled: false,
    },
    navigator: {
      enabled: showControls && !minimal,
      maskFill: "rgba(250, 145, 115, 0.35)", // Using your primary color with opacity
      series: {
        color: "#FA9173", // Primary color
        lineWidth: 1,
      },
      handles: {
        backgroundColor: "#FA9173", // Primary color
        borderColor: "#F4F2F2", // Surface variant color
      },
    },
    rangeSelector: {
      enabled: showControls && !minimal,
      buttonTheme: {
        // Style for the range selector buttons
        fill: "#F4F2F2", // Surface variant color
        stroke: "#E6DCDC", // Primary container color
        style: {
          color: "#AA9691", // On surface variant color
          fontFamily: "Monda",
          fontWeight: "normal",
        },
        r: 8, // Border radius for rounded corners
        padding: 8, // Padding inside buttons
        states: {
          hover: {
            fill: "#FA9173", // Primary color
            style: {
              color: "white", // On primary color
            },
          },
          select: {
            fill: "#FA9173", // Primary color
            style: {
              color: "white", // On primary color
            },
          },
        },
      },
      inputBoxBorderColor: "#E6DCDC", // Primary container color
      inputBoxHeight: 32,
      inputBoxRadius: 8, // Rounded corners for input boxes
      inputSpacing: 8, // Spacing between inputs
      inputStyle: {
        color: "#AA9691", // On surface variant color
        fontFamily: "Monda",
        backgroundColor: "#F4F2F2", // Surface variant color
        borderRadius: "8px",
        border: "1px solid #E6DCDC", // Primary container color
      },
      inputPosition: {
        align: "right",
        x: -10,
        y: 0,
      },
      inputDateFormat: "%e %b %Y", // More readable date format
      inputEditDateFormat: "%Y-%m-%d",
      labelStyle: {
        color: "#AA9691", // On surface variant color
        fontFamily: "Monda",
      },
      inputDateParser: function (value) {
        // Custom date parser to handle both formats
        return Date.parse(value);
      },
    },
    tooltip: {
      enabled: !minimal,
      style: {
        fontFamily: "Monda",
      },
    },
    series: processData(data),
    accessibility: {
      enabled: false,
    },
  });

  useEffect(() => {
    setChartOptions((prev) => ({
      ...prev,
      series: processData(data),
    }));
  }, [data]);

  useEffect(() => {
    window.dispatchEvent(new Event("resize"));
  }, [height, width]);

  const containerProps = useMemo(
    () => ({
      style: {
        height,
        width,
      },
    }),
    [height, width]
  );

  return (
    <div
      className={
        minimal
          ? "h-[350px] w-[350px] border-l-2 border-b-2 border-l-gray-400 border-b-gray-400 rounded-bl-xl"
          : "h-full w-full"
      }
    >
      <HighchartsReact
        containerProps={containerProps}
        highcharts={Highcharts}
        constructorType={"stockChart"}
        options={chartOptions}
        ref={chartRef}
      />
    </div>
  );
};

export default BaseChart;
