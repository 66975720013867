import { CardContent, CardHeader, CardTitle } from "../../Ui/card";
// import CardLoading from "../CardLoading";

export default function HeaderCard({
  loading = false,
  value,
  error = false,
  name,
  prefix = "",
  suffix = "",
  decimals,
}) {
  return (
    // <Card className="col-span-1">
    <div className="flex flex-col items-center justify-center h-full">
      <CardHeader>
        <CardTitle className="break-words">{name}</CardTitle>
      </CardHeader>
      <CardContent className="font-monda text-gray-700">
        {error ? (
          <div className="text-red-400">error</div>
        ) : loading ? (
          <></>
        ) : (
          `${prefix}${Number(value).toLocaleString("en-US", {
            maximumFractionDigits: decimals,
          })} ${suffix}`
        )}
      </CardContent>
    </div>

    // </Card>
  );
}
