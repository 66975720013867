// Created by James Launder inspired by tailwind ui
// combo with picture
import { useState } from "react";
import { CheckIcon, ChevronUpDownIcon } from "@heroicons/react/20/solid";
import { Combobox } from "@headlessui/react";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export default function ComboCharts(props) {
  const [query, setQuery] = useState("");

  const filteredData =
    query === ""
      ? props?.data
      : props?.data.filter((person) => {
          return person.name.toLowerCase().includes(query.toLowerCase());
        });
  const handleChange = (e) => {
    console.log(e);
    props.setSelected(e);
    props.fn(props.id, props.name, e);
  };
  return (
    <Combobox value={props.selected} onChange={handleChange}>
      <Combobox.Label className="block text-sm font-medium leading-6 text-gray-900">
        {props.title}
      </Combobox.Label>
      <div className="relative mt-2">
        <Combobox.Input
          className="w-full rounded-md border-0 bg-white py-1.5 pl-3 pr-10 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-primary sm:text-sm sm:leading-6 focus:border-primary focus:outline-none"
          onChange={(event) => setQuery(event.target.value)}
          displayValue={(person) => person?.name}
        />
        <Combobox.Button className="absolute inset-y-0 right-0 flex items-center rounded-r-md px-2 focus:outline-none ">
          <ChevronUpDownIcon
            className="h-5 w-5 text-gray-400"
            aria-hidden="true"
          />
        </Combobox.Button>

        {filteredData?.length > 0 && (
          <Combobox.Options className="absolute z-30 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
            {filteredData.map((person, idx) => (
              <Combobox.Option
                key={
                  person._id ? person._id : person.DataID ? person.DataID : idx
                }
                value={person}
                className={({ active }) =>
                  classNames(
                    "relative cursor-default select-none py-2 pl-3 pr-9 ",
                    active ? "bg-primary text-white group" : "text-gray-900"
                  )
                }
              >
                {({ active, selected }) => (
                  <>
                    <div className="flex items-center ">
                      {person.icon ? (
                        <img
                          src={process.env.API + "/" + person.icon}
                          alt=""
                          className="h-6 w-6 flex-shrink-0 rounded-full"
                        />
                      ) : (
                        <div>
                          {person.iconLocal ? person.iconLocal : <div></div>}
                        </div>
                      )}

                      <span
                        className={classNames(
                          "ml-3 truncate",
                          selected && "font-semibold"
                        )}
                      >
                        {person.name}
                      </span>
                    </div>

                    {selected && (
                      <span
                        className={classNames(
                          "absolute inset-y-0 right-0 flex items-center pr-4",
                          active ? "text-white" : "text-primary"
                        )}
                      >
                        <CheckIcon className="h-5 w-5" aria-hidden="true" />
                      </span>
                    )}
                  </>
                )}
              </Combobox.Option>
            ))}
          </Combobox.Options>
        )}
      </div>
    </Combobox>
  );
}
