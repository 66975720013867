import { useStoreDialog } from "../state/useStoreDialogs";
import DialogHeader from "./DialogHeader";

export default function Footprint() {
  const navTo = useStoreDialog((state) => state.navTo);
  const goHome = useStoreDialog((state) => state.goHome);
  const editMode = false;
  return (
    <div className="h-full w-full bg-blue-400">
      <DialogHeader
        title={editMode ? "Edit Footprint" : "Footprint"}
        onBack={goHome}
        editMode={editMode}
      />
      <span>Hello add Footprint sutff plz</span>
    </div>
  );
}
