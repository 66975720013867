import dayjs from "dayjs";
import classNames from "../../../utils/ClassNames";

export default function NewsCard({ title, link, author, published, image }) {
  return (
    <a
      key={title}
      href={link}
      target="_blank"
      rel="noopener noreferrer"
      className="bg-white flex flex-col gap-2 rounded-md h-full w-full p-2 border border-surfaceVariant shadow-md"
    >
      <div className="row-span-3 flex flex-1 flex-col">
        <p className="text-sm text-gray-900 font-semibold text-ellipsis overflow-hidden text-nowrap">
          {title}
        </p>
        <span className="text-sm text-gray-600 flex gap-2 items-center text-wrap ...">
          <p>{author}</p>
          <p>{dayjs(published * 1000).format("MM/DD/YYYY")}</p>
        </span>
      </div>

      <div className="relative mx-auto rounded-md overflow-hidden row-span-4 bg-red-400">
        <img
          src={image != undefined ? image[0]["url"] : ""}
          alt=""
          className={classNames("object-contain h-fit")}
        />
      </div>
    </a>
  );
}
