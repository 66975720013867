import * as React from "react";
import { cn } from "../../../utils/utils";

const Card = React.forwardRef(({ className, disabled, ...props }, ref) => (
  <div
    ref={ref}
    className={cn(
      "bg-surfaceVariant p-10 flex items-center shadow-md justify-center border border-secondary rounded-md transform transition duration-300 flex-col gap-6 font-semibold text-4xl text-gray-900",
      !disabled && "group hover:bg-primary hover:text-white",
      className
    )}
    {...props}
  />
));
Card.displayName = "Card";

export { Card };
