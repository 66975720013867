import { useState } from "react";
import { useStoreDialog } from "../state/useStoreDialogs";
import { useStoreDashboard } from "../state/useStoreDashboard";
import { Button } from "../../Ui/button";
import TextSettings from "../settings/TextSettings";
import DialogHeader from "./DialogHeader";

export default function TextDialog({
  editMode = false,
  initialData = null,
  onClose,
}) {
  const goHome = useStoreDialog((state) => state.goHome);
  const add = useStoreDashboard((state) => state.addItem);
  const updateItem = useStoreDashboard((state) => state.updateItem);
  const [content, setContent] = useState(initialData?.props?.content || "");

  const handleClose = () => {
    goHome();
    onClose?.();
  };

  const handleSave = () => {
    if (!content.trim()) {
      return;
    }

    const props = {
      content,
      lastModified: new Date().toISOString(),
    };

    if (editMode) {
      updateItem(initialData.widgetId, props);
    } else {
      add("Text", 2, 1, props);
    }

    handleClose();
  };

  return (
    <div className="h-full w-full flex flex-col">
      {/* Header - fixed height */}
      <DialogHeader
        title={editMode ? "Edit Text" : "Text"}
        onBack={goHome}
        editMode={editMode}
      />

      {/* Content - takes remaining space */}
      <div className="flex-1 min-h-0">
        <TextSettings content={content} onUpdate={setContent} />
      </div>

      {/* Footer - fixed height */}
      <div className="border-t p-4 flex justify-end shrink-0">
        <Button onClick={handleSave} disabled={!content.trim()}>
          {editMode ? "Update Widget" : "Add to Dashboard"}
        </Button>
      </div>
    </div>
  );
}
