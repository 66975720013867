import { useContext, useState, useEffect } from "react";
import { HexColorPicker } from "react-colorful";
import ComboCharts from "./../UI/ComboCharts";
import useChartInfoQuery from "../API/useChartInfoQuery";
import { cn } from "../../../utils/utils";
import { Tooltip } from "react-tooltip";
import { UserContext } from "../../../Services/userContext";
import { Popover, PopoverContent, PopoverTrigger } from "../../Ui/Popover";
import { XMarkIcon } from "@heroicons/react/24/outline";

const style = [
  {
    name: "Line",
    data: "line",
  },
  {
    name: "Bar",
    data: "column",
  },
  {
    name: "Spline",
    data: "spline",
  },
  {
    name: "Area",
    data: "area",
  },
  {
    name: "Scatter",
    data: "scatter",
  },
];

export default function SingleChartSettings({
  id,
  charts,
  setCharts,
  active,
  setActive,
  index,
  onRemove, // Add this prop
}) {
  const { userData } = useContext(UserContext);
  const chart = charts.find((chart) => chart.id === id);

  const [selectedBlockchain, setSelectedBlockchain] = useState(
    chart?.blockchain || null
  );
  const [selectedChart, setSelectedChart] = useState(chart?.name || null);
  const [selectedResolution, setSelectedResolution] = useState(
    chart?.resolution || null
  );
  const [selectedSource, setSelectedSource] = useState(chart?.source || null);
  const [selectedStyle, setSelectedStyle] = useState(chart?.style || null);
  const [selectedColour, setSelectedColour] = useState(
    chart?.colour || "#FA9173"
  );

  const [header, setHeader] = useState(
    `${chart?.blockchain?.name} - ${chart?.name?.name}` || "Waiting..."
  );

  const {
    blockchains: blockchains,
    blockchainsLoading: isLoading,
    charts: names,
    chartsLoading: chartsLoading,
    chartsFetching: chartsFetching,
  } = useChartInfoQuery(selectedBlockchain?.name);

  const updateFieldById = (id, fieldName, newValue) => {
    // console.log("Updating chart field:", {
    //   id,
    //   fieldName,
    //   newValue: JSON.parse(JSON.stringify(newValue)),
    // });
    setCharts((prevData) => {
      const updatedData = prevData.map((item) => {
        if (item.id === id) {
          return { ...item, [fieldName]: newValue };
        }
        return item;
      });
      // console.log(
      //   "Updated charts array:",
      //   JSON.parse(JSON.stringify(updatedData))
      // );
      return updatedData;
    });
  };

  function checkTier(res) {
    switch (userData.tier) {
      case 0:
        if (res === "Day") {
          return false;
        }
        return true;
      case 1:
        if (res === "Block" || res === "Min") {
          return true;
        }
        return false;
      default:
        return false;
    }
  }

  // useEffect to update the chart data when selections change
  useEffect(() => {
    updateFieldById(id, "blockchain", selectedBlockchain);
    updateFieldById(id, "name", selectedChart);
    updateFieldById(id, "resolution", selectedResolution);
    updateFieldById(id, "source", selectedSource);
    updateFieldById(id, "style", selectedStyle);
    updateFieldById(id, "colour", selectedColour);
  }, [
    selectedBlockchain,
    selectedChart,
    selectedResolution,
    selectedSource,
    selectedStyle,
    selectedColour,
  ]);

  useEffect(() => {
    if (selectedChart !== null && selectedBlockchain !== null) {
      const chart = charts.filter((chart) => chart.id === id)[0];
      setHeader(`${chart?.blockchain?.name} - ${chart?.name?.name}`);
    }
  }, [selectedBlockchain, selectedChart, selectedResolution]);

  return (
    <div className="h-full relative z-10">
      <button
        onClick={() => setActive(id)}
        className={cn(
          active != id ? "h-8" : "h-0",
          "overflow-hidden flex w-full items-center justify-between"
        )}
      >
        <div className="flex items-center gap-2">
          <p className="text-lg">{header}</p>
        </div>

        {onRemove && (
          <button
            onClick={(e) => {
              e.stopPropagation();
              onRemove();
            }}
            className="p-1 group"
          >
            <XMarkIcon className="h-6 w-6 text-gray-300 group-hover:text-rose-400" />
          </button>
        )}
      </button>

      <div>
        {isLoading ? (
          <div>Loading...</div>
        ) : (
          <ComboCharts
            title={"Blockchain"}
            data={blockchains?.data?.blockchain}
            selected={selectedBlockchain}
            setSelected={setSelectedBlockchain}
            name={"blockchain"}
            fn={updateFieldById}
            id={id}
          />
        )}
      </div>

      <div className="flex flex-col mt-4">
        {selectedBlockchain?.name === "None" || selectedBlockchain === null ? (
          <div>
            <div className="block text-sm font-medium leading-6 text-gray-900">
              Charts
            </div>
            <div className="truncate">Select A Blockchain...</div>
          </div>
        ) : (
          <div>
            {chartsFetching || chartsLoading ? (
              <div>
                <div className="block text-sm font-medium leading-6 text-gray-900">
                  Charts
                </div>
                <div className="truncate">Loading...</div>
              </div>
            ) : (
              <ComboCharts
                title={"Charts"}
                data={names?.data?.graphNames}
                selected={selectedChart}
                setSelected={setSelectedChart}
                name={"name"}
                fn={updateFieldById}
                id={id}
              />
            )}
          </div>
        )}
      </div>

      <div className="flex flex-col mt-4">
        {selectedChart === null ? (
          <div>
            <div className="block text-sm font-medium leading-6 text-gray-900">
              Source
            </div>
            <div className="truncate">Select A Chart...</div>
          </div>
        ) : (
          <div>
            {Array.isArray(selectedChart.sources) ? (
              <ComboCharts
                title={"Source"}
                data={selectedChart.sources}
                selected={selectedSource}
                setSelected={setSelectedSource}
                name={"source"}
                fn={updateFieldById}
                id={id}
              />
            ) : (
              <div>
                <div className="block text-sm font-medium leading-6 text-gray-900">
                  Source
                </div>
                <div className="truncate">{selectedChart.dataSource}</div>
              </div>
            )}
          </div>
        )}
      </div>

      <div className="mt-4">
        <h1 className="block text-sm font-medium leading-6 text-gray-900">
          Resolution
        </h1>
        {selectedChart === null ? (
          <div className="mt-2 h-[40px]">Select A Chart...</div>
        ) : (
          <div>
            <span className="grid grid-flow-col rounded-lg bg-white text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 mt-2">
              {selectedChart.resolution?.map((data) => (
                <>
                  <button
                    type="button"
                    key={data + " combo"}
                    onClick={() => {
                      setSelectedResolution(data);
                      updateFieldById(id, "resolution", data);
                    }}
                    disabled={checkTier(data)}
                    className={cn(
                      checkTier(data)
                        ? "text-gray-300"
                        : selectedResolution === data && !checkTier(data)
                        ? "bg-primary text-white text-bold"
                        : "text-gray-900 hover:bg-primary/30 hover:text-gray-900",
                      "rounded-lg py-1.5 text-gray-900",
                      "res-select-" + data
                    )}
                  >
                    {data}
                  </button>
                  {checkTier(data) ? (
                    <Tooltip anchorSelect={".res-select-" + data} place="top">
                      Upgrade!
                    </Tooltip>
                  ) : (
                    <></>
                  )}
                </>
              ))}
            </span>
          </div>
        )}
      </div>

      <div className="mt-4">
        <ComboCharts
          title={"Style"}
          data={style}
          selected={selectedStyle}
          setSelected={setSelectedStyle}
          name={"style"}
          fn={updateFieldById}
          id={id}
        />
      </div>

      <div className="mt-4">
        <div className="block text-sm font-medium leading-6 text-gray-900">
          Colour
        </div>
        <Popover>
          <PopoverTrigger>
            <div className="w-full flex items-center gap-2 rounded-md border-0 bg-white py-1.5 pl-3 pr-10 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-primary sm:text-sm sm:leading-6 focus:border-primary focus:outline-none">
              <div
                style={{
                  backgroundColor: selectedColour,
                }}
                className={`h-2 w-2 rounded-full`}
              ></div>
              {selectedColour}
            </div>
          </PopoverTrigger>
          <PopoverContent align={"start"} className="z-30">
            <HexColorPicker
              color={selectedColour}
              onChange={(color) => {
                updateFieldById(id, "colour", color);
                setSelectedColour(color);
              }}
              style={{ width: "100%", height: "150px" }}
            />
          </PopoverContent>
        </Popover>
      </div>
    </div>
  );
}
