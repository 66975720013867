import React from "react";
import { Responsive, WidthProvider } from "react-grid-layout";
import { Button } from "../../Ui/button";
import { Input } from "../../Ui/input";
import { Loader2, SaveIcon, PlusCircle, Lock, Unlock } from "lucide-react";
import { cn } from "../../../utils/utils";
import WidgetContent from "../widgets/WidgetContent";
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from "../../Ui/ToolTip";
import "react-grid-layout/css/styles.css";
import "react-resizable/css/styles.css";

const ResponsiveGridLayout = WidthProvider(Responsive);

const defaultCreateElement = (el, items, onDelete, defaultProps) => {
  const item = items.find((value) => value.i === el.i);
  if (!item) return null;

  return (
    <div key={el.i} className="h-full w-full">
      <WidgetContent el={el} item={item} {...defaultProps} onDelete={onDelete}>
        {defaultProps.children(item.type, item?.props)}
      </WidgetContent>
    </div>
  );
};

const DashboardLayout = ({
  dashboardName,
  onDashboardNameChange,
  layout,
  items,
  onLayoutChange,
  onRemoveWidget,
  isEditing,
  onToggleEdit,
  isSaving,
  onSave,
  onOpenDialog,
  createElement,
  children,
  onEdit,
  showEditControls = false,
  headerExtras = null,
}) => {
  // Extract header components from headerExtras
  const likeButton = headerExtras?.props?.children?.[0];
  const visibilityToggle = headerExtras?.props?.children?.[1];

  const responsiveProps = {
    className: "layout",
    breakpoints: { lg: 1200, md: 996, sm: 768, xs: 480, xxs: 0 },
    cols: { lg: 12, md: 10, sm: 6, xs: 4, xxs: 2 },
    rowHeight: 110,
    layouts: { lg: layout },
    width: 1200,
    isDraggable: isEditing && showEditControls,
    isResizable: isEditing && showEditControls,
    draggableHandle: ".react-grid-dragHandleExample",
    onLayoutChange: (newLayout) => {
      if (showEditControls && onLayoutChange) {
        onLayoutChange(newLayout);
      }
    },
    margin: [10, 10],
  };

  return (
    <div className="h-full w-full bg-surface py-2">
      <div className="px-4 py-2 flex items-center justify-between border-b">
        {/* Left Section - Public/Private Toggle */}
        <div className="flex-none">{visibilityToggle}</div>

        {/* Center Section - Dashboard Title and Like Button */}
        <div className="flex flex-1 justify-center items-center gap-2 max-w-2xl px-4">
          <div className="flex items-center gap-2 pl-2 pr-4 py-1 bg-surfaceVariant rounded-lg border-primaryContainer text-center">
            {likeButton}

            {isEditing ? (
              <Input
                className="w-64 bg-white text-center"
                value={dashboardName}
                onChange={(e) => onDashboardNameChange?.(e.target.value)}
                placeholder="Dashboard Name"
                disabled={isSaving}
              />
            ) : (
              <h1 className="text-2xl font-semibold text-center mb-1.5">
                {dashboardName}
              </h1>
            )}
          </div>
        </div>

        {/* Right Section - Edit, Save, and Add Buttons */}
        <div className="flex-none flex items-center gap-2">
          {showEditControls && (
            <>
              <TooltipProvider>
                <Tooltip>
                  <TooltipTrigger asChild>
                    <Button
                      variant="outline"
                      size="icon"
                      onClick={() => onToggleEdit?.()}
                      className="h-10 w-10 p-2 border-primaryContainer group hover:bg-primary transition-colors shadow-none"
                    >
                      {isEditing ? (
                        <Unlock className="h-5 w-5 text-onSurfaceVariant group-hover:text-white" />
                      ) : (
                        <Lock className="h-5 w-5 text-onSurfaceVariant group-hover:text-white" />
                      )}
                    </Button>
                  </TooltipTrigger>
                  <TooltipContent>
                    {isEditing
                      ? "Lock dashboard"
                      : "Unlock dashboard for editing"}
                  </TooltipContent>
                </Tooltip>
              </TooltipProvider>

              <TooltipProvider>
                <Tooltip>
                  <TooltipTrigger asChild>
                    <Button
                      variant="outline"
                      size="icon"
                      onClick={onSave}
                      disabled={isSaving}
                      className="h-10 w-10 p-2 border-primaryContainer group hover:bg-primary transition-colors shadow-none"
                    >
                      {isSaving ? (
                        <Loader2 className="h-5 w-5 animate-spin text-onSurfaceVariant" />
                      ) : (
                        <SaveIcon className="h-5 w-5 text-onSurfaceVariant group-hover:text-white" />
                      )}
                    </Button>
                  </TooltipTrigger>
                  <TooltipContent>Save dashboard changes</TooltipContent>
                </Tooltip>
              </TooltipProvider>

              <TooltipProvider>
                <Tooltip>
                  <TooltipTrigger asChild>
                    <Button
                      variant="outline"
                      size="icon"
                      onClick={onOpenDialog}
                      className="h-10 w-10 p-2 border-primaryContainer group hover:bg-primary transition-colors shadow-none"
                    >
                      <PlusCircle className="h-5 w-5 text-onSurfaceVariant group-hover:text-white" />
                    </Button>
                  </TooltipTrigger>
                  <TooltipContent>Add a new widget to dashboard</TooltipContent>
                </Tooltip>
              </TooltipProvider>
            </>
          )}
        </div>
      </div>

      <div className="overflow-hidden p-4">
        <ResponsiveGridLayout {...responsiveProps}>
          {layout.map((el) => {
            const defaultProps = {
              showEditControls,
              isEditing,
              onEdit,
              children,
            };

            if (createElement) {
              return createElement(el, items, onRemoveWidget);
            }
            return defaultCreateElement(
              el,
              items,
              onRemoveWidget,
              defaultProps
            );
          })}
        </ResponsiveGridLayout>
      </div>
    </div>
  );
};

export default DashboardLayout;
