import { useSearchParams } from "react-router-dom";
import { useQuery } from "@tanstack/react-query";
import request from "../../../Api/Api.js";

export default function useChartInfoQuery(selected) {
  const [searchParams] = useSearchParams();
  const { isLoading, data } = useQuery({
    queryKey: ["Compare-blockchains"],
    queryFn: () => request.get("/blockchains"),
  });

  //   const blockchain = data?.blockchain;
  const current = data?.blockchain?.find(
    (o) => o.name === searchParams.get("blockchain")
  );

  // query for the different charts
  const {
    isLoading: chartsLoading,
    isFetching: chartsFetching,
    data: charts,
  } = useQuery({
    queryKey: ["charts" + selected],
    queryFn: () => request.get("/stores/" + selected),
    enabled: !!data && selected != null,
  });
  return {
    blockchains: data,
    blockchainsLoading: isLoading,
    charts: charts,
    chartsLoading: chartsLoading,
    chartsFetching: chartsFetching,
    selectedBlockchain: current,
  };
}
