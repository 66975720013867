import React, { useEffect, useMemo, useRef, useCallback } from "react";
import { Dialog, DialogContent } from "../../Ui/Dialog";
import { useStoreDialog } from "../state/useStoreDialogs";
import TypePicker from "./TypePicker";
import ChartingInformation from "../settings/ChartingInformation";
import Footprint from "./Footprint";
import Query from "./Query";
import Discover from "./Discover";
import TextDialog from "./TextDialog";
import Table from "./Table";
import { cn } from "../../../utils/utils";

export default function DashboardDialog({
  open,
  setOpen,
  editMode = false,
  initialData = null,
}) {
  const { active, isEditing, editData, initialize, navTo, reset } =
    useStoreDialog();

  const hasInitialized = useRef(false);

  // Create the items array
  const items = [
    {
      id: "Home",
      item: !editMode ? <TypePicker /> : null,
    },
    {
      id: "Chart",
      item: (
        <ChartingInformation editMode={editMode} initialData={initialData} />
      ),
    },
    {
      id: "Footprint",
      item: <Footprint />,
    },
    {
      id: "Query",
      item: <Query />,
    },
    {
      id: "Discover",
      item: <Discover />,
    },
    {
      id: "Text",
      item: (
        <TextDialog
          editMode={editMode}
          initialData={initialData}
          onClose={() => setOpen(false)}
        />
      ),
    },
    {
      id: "Table",
      item: <Table />,
    },
  ].filter((item) => item.item !== null);

  // Generate TYPE_TO_INDEX dynamically based on filtered items
  const TYPE_TO_INDEX = useMemo(() => {
    return items.reduce((acc, item, index) => {
      acc[item.id] = index;
      return acc;
    }, {});
  }, [items]);

  useEffect(() => {
    if (open && !hasInitialized.current && initialData) {
      hasInitialized.current = true;
      initialize(initialData.type, editMode, initialData);

      const targetIndex = TYPE_TO_INDEX[initialData.type];
      if (targetIndex !== undefined) {
        navTo(0, targetIndex);
      }
    }
  }, [open, initialData, editMode, initialize, navTo, TYPE_TO_INDEX]);

  // Reset on close
  useEffect(() => {
    if (!open) {
      hasInitialized.current = false;
      reset();
    }
  }, [open, reset]);

  const handleClose = useCallback(() => {
    setOpen(false);
    reset();
  }, [setOpen, reset]);

  return (
    <Dialog onOpenChange={handleClose} open={open} modal>
      <DialogContent className="max-w-3xl w-full p-0">
        <div className="h-[650px] flex flex-col overflow-hidden">
          <div className="relative flex-1 grid grid-cols-1 grid-rows-1">
            {items.map((item, index) => (
              <div
                key={index}
                className={cn(
                  "col-start-1 col-end-2 row-start-1 row-end-2 w-full h-full transition-all duration-500",
                  active === index
                    ? "translate-y-0 opacity-100 z-20"
                    : active === 0
                    ? "-translate-y-full opacity-0 z-10"
                    : "translate-y-full opacity-0 z-10"
                )}
              >
                {item.item}
              </div>
            ))}
          </div>
        </div>
      </DialogContent>
    </Dialog>
  );
}
