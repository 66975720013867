import React from "react";
import {
  ChartBarIcon,
  DocumentMagnifyingGlassIcon,
  HandRaisedIcon,
  NewspaperIcon,
  DocumentTextIcon,
  TableCellsIcon,
} from "@heroicons/react/24/outline";
import { Card } from "../UI/MainCard";
import { useStoreDialog } from "../state/useStoreDialogs";
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from "../../Ui/ToolTip";
import DialogHeader from "./DialogHeader";

export default function TypePicker() {
  const navTo = useStoreDialog((state) => state.navTo);

  const widgetTypes = [
    {
      name: "Chart",
      icon: ChartBarIcon,
      onClick: () => navTo(0, 1),
      available: true,
    },
    {
      name: "Text",
      icon: DocumentTextIcon,
      onClick: () => navTo(0, 5),
      available: true,
    },
    {
      name: "Footprint",
      icon: HandRaisedIcon,
      onClick: () => navTo(0, 2),
      available: false,
    },
    {
      name: "Query",
      icon: DocumentMagnifyingGlassIcon,
      onClick: () => navTo(0, 3),
      available: false,
    },
    {
      name: "Discover",
      icon: NewspaperIcon,
      onClick: () => navTo(0, 4),
      available: false,
    },
    {
      name: "Table",
      icon: TableCellsIcon,
      onClick: () => navTo(0, 6),
      available: false,
    },
  ];

  return (
    <div className="w-full h-full">
      <DialogHeader
        title="Widgets"
        editMode={true} // We don't want this header to be clickable
      />

      <div className="grid grid-cols-3 grid-flow-row gap-6 p-6">
        {widgetTypes.map((widget, index) => (
          <TooltipProvider key={widget.name}>
            <Tooltip>
              <TooltipTrigger asChild>
                <div>
                  <button
                    onClick={widget.available ? widget.onClick : undefined}
                    className={`w-full focus:outline-none ${
                      !widget.available && "cursor-not-allowed"
                    }`}
                    disabled={!widget.available}
                  >
                    <Card
                      className={!widget.available ? "opacity-50" : ""}
                      disabled={!widget.available}
                    >
                      <widget.icon
                        className={`h-24 w-24 ${
                          widget.available
                            ? "text-onSurfaceVariant group-hover:text-white"
                            : "text-gray-400"
                        }`}
                      />
                      {widget.name}
                      {!widget.available && (
                        <span className="absolute top-2 right-2 text-xs font-medium text-gray-500">
                          Coming Soon
                        </span>
                      )}
                    </Card>
                  </button>
                </div>
              </TooltipTrigger>
              <TooltipContent>
                {widget.available
                  ? `Add ${widget.name} widget`
                  : `${widget.name} widget coming soon`}
              </TooltipContent>
            </Tooltip>
          </TooltipProvider>
        ))}
      </div>
    </div>
  );
}
