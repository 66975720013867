import React, { useState, useEffect } from "react";
import { Card } from "../../Ui/card";
import { Input } from "../../Ui/input";
import { Pencil, X, GripHorizontal } from "lucide-react";
import { Button } from "../../Ui/button";
import { cn } from "../../../utils/utils";
import { useStoreDashboard } from "../state/useStoreDashboard";

const WidgetContent = ({
  el,
  item,
  showEditControls,
  isEditing,
  onEdit,
  onDelete,
  children,
}) => {
  const [titleValue, setTitleValue] = useState(
    item?.title || `${item?.type} Widget`
  );
  const updateWidgetTitle = useStoreDashboard(
    (state) => state.updateWidgetTitle
  );

  useEffect(() => {
    setTitleValue(item?.title || `${item?.type} Widget`);
  }, [item?.title, item?.type]);

  const handleTitleUpdate = () => {
    updateWidgetTitle(item.i, titleValue);
  };

  // Determine if we should show the header based on:
  // 1. Whether we're in a dashboard (item exists)
  // 2. The widget type and edit state
  const showHeader =
    !item ||
    (item?.type !== "Text"
      ? isEditing || showEditControls
      : isEditing && showEditControls);

  // Determine if we should show the title
  const showTitle = item?.type !== "Text";

  return (
    <Card key={el.i} className="h-full w-full z-0 p-2 flex flex-col">
      {/* Only render header if showHeader is true */}
      {showHeader && (
        <div className="h-10 flex w-full items-center px-2 gap-2 py-2">
          {/* Rest of the header content stays the same */}
          {showEditControls && isEditing && (
            <Button
              variant="outline"
              size="icon"
              onClick={() => {
                onEdit?.(el.i, item);
              }}
              className="p-2 border-primaryContainer group hover:bg-primary transition-colors shadow-none"
            >
              <Pencil className="h-5 w-5 text-onSurfaceVariant group-hover:text-white" />
            </Button>
          )}

          {/* Title with Drag Handles - Center */}
          <div
            className={cn(
              "flex-1 flex justify-center items-center gap-2 react-grid-dragHandleExample",
              isEditing && showEditControls
                ? "cursor-grab active:cursor-grabbing"
                : ""
            )}
          >
            {isEditing && showEditControls && (
              <GripHorizontal
                className={cn(
                  "h-5 w-5 text-gray-400 react-grid-dragHandleExample",
                  isEditing && showEditControls
                    ? "cursor-grab active:cursor-grabbing"
                    : ""
                )}
              />
            )}
            {showTitle && (
              <div
                className={cn(
                  "flex items-center justify-center react-grid-dragHandleExample px-4",
                  isEditing && showEditControls
                    ? "cursor-grab active:cursor-grabbing"
                    : ""
                )}
              >
                {isEditing && showEditControls ? (
                  <Input
                    value={titleValue}
                    onChange={(e) => {
                      setTitleValue(e.target.value);
                      handleTitleUpdate();
                    }}
                    onBlur={handleTitleUpdate}
                    className="text-sm w-48 text-center"
                  />
                ) : (
                  <span className="text-sm font-medium truncate text-center">
                    {item?.title || `${item?.type} Widget`}
                  </span>
                )}
              </div>
            )}
            {isEditing && showEditControls && (
              <GripHorizontal
                className={cn(
                  "h-5 w-5 text-gray-400 react-grid-dragHandleExample",
                  isEditing && showEditControls
                    ? "cursor-grab active:cursor-grabbing"
                    : ""
                )}
              />
            )}
          </div>

          {/* Delete Button - Right */}
          {showEditControls && isEditing && (
            <Button
              variant="outline"
              size="icon"
              onClick={() => onDelete(el.i)}
              className="p-2 border-primaryContainer group hover:bg-red-100 hover:text-red-500 transition-colors shadow-none"
            >
              <X className="h-5 w-5 text-onSurfaceVariant group-hover:text-red-500" />
            </Button>
          )}
        </div>
      )}

      {/* Main content with dynamic height based on header presence */}
      <div className={cn("w-full bg-white flex-1 min-h-0")}>{children}</div>
    </Card>
  );
};

export default WidgetContent;
