import { create } from "zustand";
import { persist } from "zustand/middleware";

const MAX_RECENT_DASHBOARDS = 3;

export const useStoreRecent = create(
  persist(
    (set) => ({
      recentDashboards: [],

      addRecentDashboard: (dashboard) =>
        set((state) => {
          // Remove the dashboard if it already exists
          const filtered = state.recentDashboards.filter(
            (d) => d.uniqueCode !== dashboard.uniqueCode
          );

          // Add the dashboard to the start and limit to MAX_RECENT
          return {
            recentDashboards: [dashboard, ...filtered].slice(
              0,
              MAX_RECENT_DASHBOARDS
            ),
          };
        }),

      clearRecentDashboards: () => set({ recentDashboards: [] }),
    }),
    {
      name: "recent-dashboards-storage",
    }
  )
);
