/* eslint-disable no-unused-vars */
/* eslint-disable react/no-unescaped-entities */
import React from "react";
import { useNavigate, useOutletContext } from "react-router-dom";
import { Card } from "../../Ui/card";
import { ScrollArea } from "../../Ui/ScrollArea";
import {
  LayoutDashboardIcon,
  Loader2,
  XIcon,
  HeartIcon,
  TrendingUpIcon,
  LogIn,
  PlusIcon,
} from "lucide-react";
import { Button } from "../../Ui/button";
import { isAuthenticated } from "../../../utils/auth";
import {
  useUserDashboards,
  useDeleteDashboard,
  useLikedDashboards,
  usePopularDashboards,
} from "../API/useDashboardQueries";
import {
  AlertDialog,
  AlertDialogAction,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
  AlertDialogTrigger,
} from "../../Ui/AlertDialog";
import { useToast } from "../../Ui/use-toast";

const DashboardCard = ({ dashboard, onDelete, showDelete = false }) => {
  return (
    <Card className="p-6 hover:border-primary transition-colors relative h-full flex flex-col">
      {showDelete && (
        <AlertDialog>
          <AlertDialogTrigger asChild>
            <Button
              variant="icon"
              className="absolute top-2 right-2 p-0 rounded-full bg-gray-50 hover:bg-red-100 group hover:text-red-500 border border-gray-200 transition-colors"
              onClick={(e) => e.stopPropagation()}
            >
              <XIcon className="h-4 w-4 text-gray-500 group-hover:text-red-500 transition-colors" />
            </Button>
          </AlertDialogTrigger>
          <AlertDialogContent>
            <AlertDialogHeader>
              <AlertDialogTitle>Delete Dashboard</AlertDialogTitle>
              <AlertDialogDescription>
                Are you sure you want to delete "{dashboard.dashboardName}"?
                This action cannot be undone.
              </AlertDialogDescription>
            </AlertDialogHeader>
            <AlertDialogFooter>
              <AlertDialogCancel>Cancel</AlertDialogCancel>
              <AlertDialogAction
                className="bg-red-500 hover:bg-red-600"
                onClick={(e) => onDelete(e, dashboard.uniqueCode)}
              >
                Delete
              </AlertDialogAction>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialog>
      )}

      <div className="flex items-center gap-3 mb-4">
        <LayoutDashboardIcon className="h-6 w-6 text-primary flex-shrink-0" />
        <h3 className="text-lg font-semibold truncate">
          {dashboard.dashboardName}
        </h3>
      </div>

      <div className="space-y-3 text-sm text-gray-500 flex-grow">
        <div className="flex items-center gap-2">
          <HeartIcon className="h-4 w-4 flex-shrink-0" />
          <span>{dashboard.likes || 0} likes</span>
        </div>
        <div>
          Last accessed:{" "}
          {new Date(dashboard.dateLastAccessed).toLocaleDateString()}
        </div>
        <div className="flex items-center gap-2">
          <span>{dashboard.items.length} widgets</span>
          <span>•</span>
          <span>{dashboard.isPublic ? "Public" : "Private"}</span>
        </div>
      </div>
    </Card>
  );
};

const DashboardSection = ({
  dashboards,
  isLoading,
  error,
  onDashboardSelect,
  onDelete,
  showDelete = false,
  twoColumns = false,
}) => {
  if (error) {
    return (
      <div className="text-destructive text-center py-4">
        Error loading dashboards: {error.message}
      </div>
    );
  }

  if (isLoading) {
    return (
      <div className="flex items-center justify-center py-8">
        <Loader2 className="h-6 w-6 animate-spin text-primary" />
      </div>
    );
  }

  if (!dashboards?.length) {
    return (
      <div className="text-muted-foreground text-center py-4">
        No dashboards found
      </div>
    );
  }

  return (
    <div
      className={`grid ${
        twoColumns ? "grid-cols-1 md:grid-cols-2" : "grid-cols-1"
      } gap-4 p-4`}
    >
      {dashboards.map((dashboard) => (
        <button
          key={dashboard.uniqueCode}
          onClick={() => onDashboardSelect(dashboard)}
          className="w-full text-left"
        >
          <DashboardCard
            dashboard={dashboard}
            onDelete={onDelete}
            showDelete={showDelete}
          />
        </button>
      ))}
    </div>
  );
};

const DashboardList = () => {
  const { onDashboardSelect } = useOutletContext();
  const navigate = useNavigate();
  const { toast } = useToast();
  const isLoggedIn = isAuthenticated();

  const {
    data: myDashboards,
    isLoading: isLoadingMy,
    error: errorMy,
  } = useUserDashboards();
  const { data: likedDashboards, isLoading: isLoadingLiked } =
    useLikedDashboards();
  const {
    data: popularDashboards,
    isLoading: isLoadingPopular,
    error: errorPopular,
  } = usePopularDashboards();

  const { mutate: deleteDashboard } = useDeleteDashboard({
    onSuccess: () => {
      toast({
        variant: "successV2",
        title: "Dashboard deleted successfully",
      });
    },
  });

  const handleDelete = (e, uniqueCode) => {
    e.stopPropagation();
    deleteDashboard(uniqueCode);
  };

  if (!isLoggedIn) {
    return (
      <div className="container mx-auto p-8">
        <div className="flex flex-col items-center justify-center space-y-4 text-center mb-8">
          <h1 className="text-3xl font-bold">Welcome to Dashboards</h1>
          <p className="text-lg text-gray-600 max-w-md">
            Create and manage your own custom dashboards. Please log in to get
            started.
          </p>
          <Button onClick={() => navigate("/signin")} className="mt-4">
            <LogIn className="h-4 w-4 mr-2" />
            Log In to Continue
          </Button>
        </div>

        <div>
          <div className="p-4 border-b">
            <h2 className="text-xl font-semibold flex items-center gap-2">
              <TrendingUpIcon className="h-5 w-5 text-primary" />
              Popular Dashboards
            </h2>
          </div>
          <DashboardSection
            dashboards={popularDashboards?.data}
            isLoading={isLoadingPopular}
            error={errorPopular}
            onDashboardSelect={onDashboardSelect}
          />
        </div>
      </div>
    );
  }

  return (
    <div className="container mx-auto p-8">
      <div className="flex justify-between items-center mb-4 pb-4 border-b">
        <h1 className="text-3xl font-bold">Dashboards</h1>
        <Button onClick={() => onDashboardSelect(null)} className="gap-2">
          <PlusIcon className="h-4 w-4" />
          New Dashboard
        </Button>
      </div>

      {/* Top Section with Liked and Popular */}
      <div className="grid md:grid-cols-2 gap-8">
        {/* Liked Dashboards */}
        <div>
          <h2 className="text-xl font-semibold flex items-center gap-2 px-2 mb-4">
            <HeartIcon className="h-5 w-5 text-primary" />
            Liked Dashboards
          </h2>
          <ScrollArea className="h-[350px]">
            <DashboardSection
              dashboards={likedDashboards?.data}
              isLoading={isLoadingLiked}
              onDashboardSelect={onDashboardSelect}
            />
          </ScrollArea>
        </div>

        {/* Popular Dashboards */}
        <div className="md:pl-8 border-l">
          <h2 className="text-xl font-semibold flex items-center gap-2 px-2 mb-4">
            <TrendingUpIcon className="h-5 w-5 text-primary" />
            Popular Dashboards
          </h2>
          <ScrollArea className="h-[350px]">
            <DashboardSection
              dashboards={popularDashboards?.data}
              isLoading={isLoadingPopular}
              error={errorPopular}
              onDashboardSelect={onDashboardSelect}
            />
          </ScrollArea>
        </div>
      </div>

      <div className="border-b my-8" />

      {/* My Dashboards Section */}
      <div>
        <h2 className="text-xl font-semibold flex items-center gap-2 px-2 mb-4">
          <LayoutDashboardIcon className="h-5 w-5 text-primary" />
          My Dashboards
        </h2>
        <DashboardSection
          dashboards={myDashboards?.data}
          isLoading={isLoadingMy}
          error={errorMy}
          onDashboardSelect={onDashboardSelect}
          onDelete={handleDelete}
          showDelete={true}
          twoColumns={true}
        />
      </div>
    </div>
  );
};

export default DashboardList;
