/* eslint-disable no-unused-vars */
import { useQuery, useMutation, useQueryClient } from "@tanstack/react-query";
import request from "../../../Api/Api.js";
import { isAuthenticated } from "../../../utils/auth";

function getToken() {
  if (localStorage.getItem("user") != null) {
    return JSON.parse(localStorage.getItem("user")).accessToken;
  }
  return null;
}

const createAuthConfig = () => {
  const token = getToken();
  if (!token) {
    return {}; // Return empty config if no token
  }
  return {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
};

const handleAuthError = (error) => {
  // Check if error is due to authentication
  if (error.response?.status === 401) {
    // Clear any invalid tokens
    localStorage.removeItem("user");
    // Throw a user-friendly error
    throw new Error("Your session has expired. Please log in again.");
  }

  // Handle permission errors
  if (error.response?.status === 403) {
    throw new Error("You don't have permission to perform this action.");
  }

  // Handle other errors
  throw error;
};

// Hook to fetch all dashboards for a user
export function useUserDashboards() {
  return useQuery({
    queryKey: ["dashboards"],
    queryFn: async () => {
      try {
        return await request.get(`/dashboards/user`, createAuthConfig());
      } catch (error) {
        handleAuthError(error);
      }
    },
    // Only fetch if user is authenticated
    enabled: isAuthenticated(),
  });
}

// Hook to fetch a single dashboard
export function useDashboard(uniqueCode) {
  return useQuery({
    queryKey: ["dashboard", uniqueCode],
    queryFn: async () => {
      try {
        return await request.get(
          `/dashboards/${uniqueCode}`,
          createAuthConfig()
        );
      } catch (error) {
        handleAuthError(error);
      }
    },
    enabled: !!uniqueCode,
    retry: (failureCount, error) => {
      // Don't retry on 401/403 errors
      if (error.response?.status === 401 || error.response?.status === 403) {
        return false;
      }
      return failureCount < 3;
    },
  });
}

// Hook to create/update dashboard
export function useDashboardMutation(options = {}) {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async ({ uniqueCode, data }) => {
      try {
        if (uniqueCode) {
          return await request.put(
            `/dashboards/${uniqueCode}`,
            data,
            createAuthConfig()
          );
        }
        return await request.post(
          "/dashboards/create",
          data,
          createAuthConfig()
        );
      } catch (error) {
        handleAuthError(error);
      }
    },
    onSuccess: (data, variables) => {
      queryClient.invalidateQueries({ queryKey: ["dashboards"] });
      if (variables.uniqueCode) {
        queryClient.invalidateQueries({
          queryKey: ["dashboard", variables.uniqueCode],
        });
      }

      if (options.onSuccess) {
        options.onSuccess(data);
      }
    },
    onError: (error, _variables, _context) => {
      if (options.onError) {
        options.onError(error);
      }
    },
  });
}

// Hook to delete dashboard
export function useDeleteDashboard(options = {}) {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async (uniqueCode) => {
      try {
        return await request.delete(
          `/dashboards/${uniqueCode}`,
          createAuthConfig()
        );
      } catch (error) {
        handleAuthError(error);
      }
    },
    onSuccess: (data, _variables) => {
      queryClient.invalidateQueries({ queryKey: ["dashboards"] });

      if (options.onSuccess) {
        options.onSuccess(data);
      }
    },
    onError: (error) => {
      if (options.onError) {
        options.onError(error);
      }
    },
  });
}

// Hook for toggling dashboard public status
export function useToggleDashboardPublic() {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async ({ uniqueCode, isPublic }) => {
      try {
        return await request.put(
          `/dashboards/${uniqueCode}/visibility`,
          { isPublic },
          createAuthConfig()
        );
      } catch (error) {
        handleAuthError(error);
      }
    },
    onSuccess: (_data, variables) => {
      queryClient.invalidateQueries({ queryKey: ["dashboards"] });
      queryClient.invalidateQueries({
        queryKey: ["dashboard", variables.uniqueCode],
      });
    },
  });
}

// Hook to get user's liked dashboards
export function useLikedDashboards() {
  return useQuery({
    queryKey: ["liked-dashboards"],
    queryFn: async () => {
      try {
        return await request.get("/dashboards/liked", createAuthConfig());
      } catch (error) {
        handleAuthError(error);
      }
    },
    enabled: isAuthenticated(),
  });
}

// Hook for liking/unliking dashboards
export function useDashboardLike() {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async ({ uniqueCode, isLiking }) => {
      try {
        const response = await request.post(
          `/dashboards/${uniqueCode}/${isLiking ? "like" : "unlike"}`,
          {},
          createAuthConfig()
        );
        return response;
      } catch (error) {
        handleAuthError(error);
      }
    },
    onMutate: async ({ uniqueCode, isLiking }) => {
      // Cancel any outgoing refetches
      await queryClient.cancelQueries({ queryKey: ["dashboard", uniqueCode] });

      // Snapshot the previous value
      const previousDashboard = queryClient.getQueryData([
        "dashboard",
        uniqueCode,
      ]);

      // Optimistically update the dashboard
      queryClient.setQueryData(["dashboard", uniqueCode], (old) => ({
        ...old,
        data: {
          ...old.data,
          likes: old.data.likes + (isLiking ? 1 : -1),
          likedBy: isLiking
            ? [
                ...(old.data.likedBy || []),
                JSON.parse(localStorage.getItem("user")).id,
              ]
            : old.data.likedBy.filter(
                (id) => id !== JSON.parse(localStorage.getItem("user")).id
              ),
        },
      }));

      return { previousDashboard };
    },
    onError: (_error, _variables, context) => {
      // Revert to the previous value on error
      if (context?.previousDashboard) {
        queryClient.setQueryData(
          ["dashboard", context.previousDashboard.data.uniqueCode],
          context.previousDashboard
        );
      }
    },
    onSettled: (data, _error, variables) => {
      // Always refetch to ensure server-client consistency
      queryClient.invalidateQueries({
        queryKey: ["dashboard", variables.uniqueCode],
      });
      queryClient.invalidateQueries({ queryKey: ["liked-dashboards"] });
    },
  });
}

// Hook to fetch popular dashboards
export function usePopularDashboards() {
  return useQuery({
    queryKey: ["popular-dashboards"],
    queryFn: async () => {
      try {
        return await request.get("/dashboards/popular");
      } catch (error) {
        handleAuthError(error);
      }
    },
  });
}
