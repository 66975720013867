import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import { useState, useEffect, useMemo } from "react";

export default function SmallChart() {
  var newStyle = document.createElement("style");
  newStyle.appendChild(
    document.createTextNode(
      "\
  @font-face {\
    font-family: 'Monda';\
    font-style: sans-serif;\
    font-weight: 400;\
    src: url('https://fonts.googleapis.com/css2?family=Monda&display=swap')\
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;\
  }\
  "
    )
  );
  const data = [
    [1643639400000, 174.78],
    [1643725800000, 174.61],
    [1643812200000, 175.84],
    [1643898600000, 172.9],
    [1643985000000, 172.39],
    [1644244200000, 171.66],
    [1644330600000, 174.83],
    [1644417000000, 176.28],
    [1644503400000, 172.12],
    [1644589800000, 168.64],
    [1644849000000, 168.88],
    [1644935400000, 172.79],
    [1645021800000, 172.55],
    [1645108200000, 168.88],
    [1645194600000, 167.3],
    [1645540200000, 164.32],
    [1645626600000, 160.07],
    [1645713000000, 162.74],
    [1645799400000, 164.85],
    [1646058600000, 165.12],
    [1646145000000, 163.2],
    [1646231400000, 166.56],
    [1646317800000, 166.23],
    [1646404200000, 163.17],
    [1646663400000, 159.3],
    [1646749800000, 157.44],
    [1646836200000, 162.95],
    [1646922600000, 158.52],
    [1647009000000, 154.73],
    [1647264600000, 150.62],
    [1647351000000, 155.09],
    [1647437400000, 159.59],
    [1647523800000, 160.62],
    [1647610200000, 163.98],
    [1647869400000, 165.38],
    [1647955800000, 168.82],
    [1648042200000, 170.21],
    [1648128600000, 174.07],
    [1648215000000, 174.72],
    [1648474200000, 175.6],
    [1648560600000, 178.96],
    [1648647000000, 177.77],
    [1648733400000, 174.61],
    [1648819800000, 174.31],
    [1649079000000, 178.44],
    [1649165400000, 175.06],
    [1649251800000, 171.83],
    [1649338200000, 172.14],
    [1649424600000, 170.09],
    [1649683800000, 165.75],
    [1649770200000, 167.66],
    [1649856600000, 170.4],
    [1649943000000, 165.29],
    [1650288600000, 165.07],
    [1650375000000, 167.4],
    [1650461400000, 167.23],
    [1650547800000, 166.42],
    [1650634200000, 161.79],
    [1650893400000, 162.88],
    [1650979800000, 156.8],
    [1651066200000, 156.57],
    [1651152600000, 163.64],
    [1651239000000, 157.65],
    [1651498200000, 157.96],
    [1651584600000, 159.48],
    [1651671000000, 166.02],
    [1651757400000, 156.77],
    [1651843800000, 157.28],
    [1652103000000, 152.06],
    [1652189400000, 154.51],
    [1652275800000, 146.5],
    [1652362200000, 142.56],
    [1652448600000, 147.11],
    [1652707800000, 145.54],
    [1652794200000, 149.24],
    [1652880600000, 140.82],
    [1652967000000, 137.35],
    [1653053400000, 137.59],
    [1653312600000, 143.11],
    [1653399000000, 140.36],
    [1653485400000, 140.52],
    [1653571800000, 143.78],
    [1653658200000, 149.64],
    [1654003800000, 148.84],
    [1654090200000, 148.71],
    [1654176600000, 151.21],
    [1654263000000, 145.38],
    [1654522200000, 146.14],
    [1654608600000, 148.71],
    [1654695000000, 147.96],
    [1654781400000, 142.64],
    [1654867800000, 137.13],
    [1655127000000, 131.88],
    [1655213400000, 132.76],
    [1655299800000, 135.43],
    [1655386200000, 130.06],
    [1655472600000, 131.56],
    [1655818200000, 135.87],
    [1655904600000, 135.35],
    [1655991000000, 138.27],
  ];
  document.head.appendChild(newStyle);
  const dataSource = [
    {
      name: "small",
      data: data,
      color: "#FA9173",
      backgroundColor: "transparent",
      animation: false,
      lineWidth: 1,
      shadow: false,
      states: {
        hover: {
          lineWidth: 1,
        },
      },
      marker: {
        radius: 1,
        states: {
          hover: {
            radius: 2,
          },
        },
      },
      fillOpacity: 0.25,
    },
  ];
  const [options, setChartOptions] = useState({
    exporting: {
      enabled: false,
      enableImages: true,
    },
    chart: {
      type: "area",
      backgroundColor: "transparent",
    },
    title: {
      text: "",
    },
    xAxis: {
      visible: false,
      type: "datetime",
    },
    yAxis: {
      visible: false,
    },
    legend: {
      enabled: false,
    },
    series: dataSource,
    credits: {
      enabled: false,
    },
    accessibility: {
      enabled: false,
    },
  });

  useEffect(() => {
    setChartOptions({
      series: dataSource,
    });
    window.dispatchEvent(new Event("resize"));
  }, []);

  const containerProps = useMemo(
    () => ({
      style: {
        height: "100%",
        width: "100%",
      },
    }),
    []
  );
  return (
    <HighchartsReact
      highcharts={Highcharts}
      containerProps={containerProps}
      options={options}
    />
  );
}
